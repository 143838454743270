import { IconButton, Tooltip } from "@mui/material"
import { toolbarItems } from "../ToolbarItems/ToolbarItems"

const ToolsSection = ({position, handleIconClick})=>{

    return  <div className="toolbar_icons">
    {toolbarItems.filter((e) => e.position == position).map((item) => (
      <Tooltip title={item.title}>
        <IconButton
          key={item.id}
          color="inherit"
          onClick={() => handleIconClick(item)}
        >
          {item.icon}
        </IconButton>
      </Tooltip>
    ))}
  </div>
}




export {ToolsSection}