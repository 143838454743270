import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS.js";

const createAOILayerWMS = (defaultLayer, onMapClick, map) =>{
    const layerObj = Object.assign({}, defaultLayer);

    layerObj.id = "layer-aoi";
    layerObj.opacity = 1;
    layerObj.map = map
    layerObj.name =
      "Delimitación de la zona de estudio";
    layerObj.zIndex = 41;
    layerObj.layer = new TileLayer({
      id: "poc_solar_amortization:fill_color",
      source: new TileWMS({
        url: "https://api.asbestos.ai/geoserver/agforest/wms",
        params: {
          LAYERS: "agforest:layer_aoi_limits",
          FORMAT: "image/png",
          //STYLES: "fill_color",
          TILED: true,
          SRS: "EPSG:4326", // Usar CRS para la versión 1.3.0 del WMS
          WIDTH: 768,
          HEIGHT: 600,
          VERSION: "1.3.0",
          
        },
        cql_filter:"uuid = 'e1f83f7c-3fdb-4920-952e-eeac592157dc'",
        projection: "EPSG:4326",
        serverType: "geoserver",
      }),
    });

    layerObj.onClickGetInfo = onMapClick;
    return layerObj;
  }

export {createAOILayerWMS}