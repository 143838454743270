
import React from 'react';


function mediaTop10Valores(objetos, property_name) {
    // Ordenar los objetos de mayor a menor en base a la propiedad 'valor'
    const top10 = objetos
      .sort((a, b) => b.get(property_name) - a.get(property_name)) // Ordena de mayor a menor
      .slice(0, 10); // Tomar los primeros 10 elementos
    
    // Calcular la suma de los valores con reduce
    const sumaTop10 = top10.reduce((suma, obj) => suma + obj.get(property_name), 0);
    
    // Calcular la media dividiendo por 10, o el número de elementos si hay menos de 10
    return top10.length > 0 ? sumaTop10 / top10.length : 0;
  }
  


const featureToTableStr = (feature) => {
    // Obtener las propiedades de la feature
    console.log("Convirtiendo en tabla feature", feature)
    const properties = feature.getProperties();

    // Crear un string con la tabla en HTML
    let tableHTML = '<table border="1" style="border-collapse: collapse; width: 100%;">';
    tableHTML += '<thead><tr><th>Propiedad</th><th>Valor</th></tr></thead>';
    tableHTML += '<tbody>';

    // Iterar sobre las propiedades y agregar filas a la tabla
    for (const [key, value] of Object.entries(properties)) {
        tableHTML += `<tr><td>${key}</td><td>${value}</td></tr>`;
    }

    tableHTML += '</tbody></table>';

    return tableHTML; // Devolver el HTML de la tabla
}

export { mediaTop10Valores}