
import {

    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
  } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
  } from "react-device-detect";
  import { toolbarItems } from "../ToolbarItems/ToolbarItems";


const ToolsDrawer = ({openDrawer, setOpenDrawer, selectedItem, setSelectedItem, handleDrawerClose, handleItemClick})=>{

    return       <Drawer
    anchor="left"
    open={openDrawer}
    onClose={handleDrawerClose}
    variant="persistent" // Siempre temporal para que se pueda cerrar al hacer clic fuera
    PaperProps={{
      style: {
        backgroundColor:
          "color-mix(in srgb, rgba(var(--color-primary-raw), 0.5) 20% , rgba(0, 0, 0, 0.5) 80%)", // Fondo semi-transparente rgba(var(--color-secondary-raw), 0.1); color-mix(in srgb, var(--color-primary) 10%, white 90%);
        backdropFilter: "blur(10px)", // Aplicar desenfoque al fondo
        WebkitBackdropFilter: "blur(10px)", // Soporte para Safari
        borderRight: "3px solid var(--color-secondary)",
      },
    }}
  >
    <div
      className="drawer-container"
      style={{
        width:
          selectedItem && selectedItem.drawerWidth
            ? selectedItem.drawerWidth
            : "",
      }}
      // style={{
      //   width: isMobile?"100vw":!selectedItem?250:selectedItem.drawerWidth?selectedItem.drawerWidth:250,
      //   height:"100%",
      //   position: 'relative',
      // //  borderRight: "3px solid var(--color-secondary)",
      //   color:"white",
      //   backgroundColor: 'color-mix(in srgb, rgba(var(--color-primary-raw), 0.5) 20% , rgba(0, 0, 0, 0.5) 80%)', // Fondo semi-transparente rgba(var(--color-secondary-raw), 0.1); color-mix(in srgb, var(--color-primary) 10%, white 90%);
      // }}
    >
      {/* Botón de cierre en la esquina superior derecha */}
      <IconButton
        onClick={handleDrawerClose}
        style={{ position: "absolute", top: 0, right: 0, color: "white" }}
      >
        <CloseIcon />
      </IconButton>
      {selectedItem ? (
        <div style={{ padding: 0, paddingTop: 48 }}>
          <h2 style={{ paddingLeft: "1rem" }}>{selectedItem.title}</h2>
          {selectedItem.component}
        </div>
      ) : (
        <>
        <div style={{height:"9%"}}>
          <h4
            style={{
              color: "(var(--color-primary-txt)",
              paddingTop: "1rem",
            }}
          >
            Herramientas:
          </h4>
          <Divider
            style={{
              borderColor: "var(--color-primary-light-80)",
              paddingTop: "1rem",
            }}
            variant="middle"
          />
          </div>
          <div style={{height:"85%", scrollY:"auto"}}>
          <div style={{height:"80%"}}>
          <List>
            {toolbarItems.filter((e)=>e.position != "end").map((item) => (
              <ListItem
                button
                key={item.id}
                onClick={() => handleItemClick(item)}
              >
                <ListItemIcon style={{ color: "var(--color-primary-txt)" }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  style={{ color: "var(--color-primary-txt)" }}
                  primary={item.title}
                />
              </ListItem>
            ))}
          </List>
          </div>
          <div style={{height:"20%"}}>
          <Divider
            style={{
              borderColor: "var(--color-primary-light-80)",
            }}
            variant="middle"
          />
          <List style={{height:"100%"}}>
          {toolbarItems.filter((e)=>e.position == "end").map((item) => (
              <ListItem
                button
                key={item.id}
                onClick={() => handleItemClick(item)}
              >
                <ListItemIcon style={{ color: "var(--color-primary-txt)" }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  style={{ color: "var(--color-primary-txt)" }}
                  primary={item.title}
                />
              </ListItem>
            ))}
          </List>
          </div>
          </div>
          
         
        </>
      )}
    </div>
  </Drawer>
}

export {ToolsDrawer}