
import AppContext from "../../Contexts/AppContext";
import LayerGroupAOI from "../../Map/Layers/LayerGroups/LayerAOI/LayerGroupAOI";
import LayerGroupSolar from "../../Map/Layers/LayerGroups/LayerSolar/LayerGroupSolar";
import MapComponent from "../../Map/MapComponent";
import React, { useState, useEffect, useRef, useContext } from 'react';
import { FloatingWindow } from "../../modals/FloatingWindow/FloatingWindow";
import Header from "../../Interfaces/Header/Header";
import {BaseLegend} from "../../Map/Legends/DefaultLegendClass"
import { z_index_values } from "../../../services/Commons/zIndexService";
import { center_map_from_extent, get_aoi_extent, getCurrentAoiUuid } from "../../../services/MapCore/AOIService";
import { isMobile } from "react-device-detect";
import Notiflix from "notiflix";
const Main = ()=>{
    // const windowWidth = useRef(Document.windowWidth);
    // const windowHeight = useRef(Document.windowHeight)//window.innerHeight);
    const [isLegendOpen, setIsLegendOpen] = useState(true);
    const [windowDimensions, setWindowDimensions] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    const [legend, setLegend] = useState(<></>)
    const {
      generalConfigParams, setGeneralConfigParams, 
      layersGroups, setLayersGroups,
      clickViews, setClickViews,
      legendUpdater,
      map,mapInstanceRef, 
      aoiUuid, setAoiUuid,layersGroupsRef,
      aoiExtent, setAoiExtent,
      isWindowFeatureDetailsOpen, setIsWindowFeatureDetailsOpen,
      isWindowLayerDashboardOpen, setIsWindowLayerDashboardOpen,
      dashboardsToShow, setDashboardsToShow,
  } = useContext(AppContext)
  
    useEffect(() => {
      const handleResize = () => {
        setWindowDimensions({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };
      
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      }
    }, [])
    

    const updateAoiUUID = async()=>{
      const current_aoi_uuid = await getCurrentAoiUuid()
      if(aoiUuid != current_aoi_uuid){
        setAoiUuid(current_aoi_uuid)
        const host = 'https://api.asbestos.ai/geoserver/ows?service=WFS&version=2.0.0&request=GetFeature&outputFormat=application/json&srsname=EPSG:4326';
        const aoi_extent = await get_aoi_extent(host, "agforest:layer_aoi_limits", encodeURI(`uuid='${current_aoi_uuid}'`));
       // console.log("Extent obtenido", aoi_extent)
        //center_map_from_extent(map, aoi_extent)
        if(aoi_extent){
         // console.log("Extent establecido", aoi_extent)
          setAoiExtent(aoi_extent)
          center_map_from_extent(mapInstanceRef.current, aoi_extent)
        }
      }
      
     
    }

    useEffect(()=>{
      if(mapInstanceRef.current){
        updateAoiUUID()
      }
      
      
    }, [mapInstanceRef])

    useEffect(()=>{
      if(mapInstanceRef.current && aoiUuid){

     //   console.log("añadiendo capas.", aoiUuid)
        setLayersGroups([
            new LayerGroupAOI(mapInstanceRef.current),
            new LayerGroupSolar(mapInstanceRef.current)
        ])


        setIsWindowFeatureDetailsOpen(false);
        setIsWindowLayerDashboardOpen(false);
        setDashboardsToShow([]);
        setTimeout(()=>{
          document.removeEventListener("dashboard-open-request", handleDashboardEvent);
          document.addEventListener( "dashboard-open-request", handleDashboardEvent);
        }, 1000)
        
      }else{

      }
      return ()=>{
        document.removeEventListener("dashboard-open-request", handleDashboardEvent);
      }
    }, [aoiUuid,mapInstanceRef ])
    
    // useEffect(()=>{
    //  // console.log("isWindowFeatureDetailsOpen", isWindowFeatureDetailsOpen)
    // }, [isWindowFeatureDetailsOpen])
    


   const handleCloseWindowFeatureDetails= () => {
    setIsWindowFeatureDetailsOpen(false);
    if(mapInstanceRef.current){
        let markerLayer = mapInstanceRef.current.getLayers().getArray().find(layer => layer.get('name') === 'markerLayer');
        if (markerLayer) {
            markerLayer.getSource().clear();
        }
    }
   
   };

   const handleDashboardEvent = ()=>{
    console.log("Abriendo dashboard", layersGroups, layersGroupsRef.current)
    if(!layersGroupsRef) return
    let dashboards = layersGroupsRef.current.filter(lg=>lg.has_dashboard).map((selectedLayer)=>{return {
        id:selectedLayer.id,
        dashboard:selectedLayer.dashboard,
        layerGroup:selectedLayer
      }})
    setDashboardsToShow()
    
    if(dashboards.length > 0){
      // if(isWindowLayerDashboardOpen){
      //   Notiflix.Notify.warning("El dashboard ya está abierto")
      // }
      setDashboardsToShow(dashboards)
      setIsWindowLayerDashboardOpen(true)
    }else{
      Notiflix.Notify.warning("No hay dashboards disponibles para mostrar")
    }
   }
   const handleCloseWindowDashboard= () => {
    setIsWindowLayerDashboardOpen(false);
    // if(mapInstanceRef.current){
    //     let markerLayer = mapInstanceRef.current.getLayers().getArray().find(layer => layer.get('name') === 'markerLayer');
    //     if (markerLayer) {
    //         markerLayer.getSource().clear();
    //     }
    // }
   
   };

    
    return <>

    <div style={{ height: "100vh",boxSizing:"border-box", position: "relative", overflow:"hidden" }}>
    <Header
          style={{
          backgroundColor: "blue",

        }}
        showHelpButton={false}
        enableToolbar={true}
        enableSearchBar={true}
      ></Header>
      <div
        style={{
          width: "100%",
          // maxWidth: "100vw",
          boxSizing:"border-box",
          height:"100%",
          //minHeight: "calc(100vh - 12rem - var(--header-height)",
        }}
      >
     
            <MapComponent  
            generalConfigParams={generalConfigParams}
        />
         {isWindowFeatureDetailsOpen && clickViews.length > 0 && (
           <FloatingWindow
             title="Detalles de la selección"
             content={clickViews.map((view, index) => {
               //console.log("Procesando view")
               return <div key={index}>
                 {view.view}
               </div>
             })}
             onClose={handleCloseWindowFeatureDetails}
            //  initial_position_x={isMobile ?  windowDimensions.width * 0.004 : windowDimensions.width - (windowDimensions.width*0.4 + 50)}
            //  initial_position_y={ isMobile? 120 :5}
            allow_maximize={true}
            initial_position_x={15}
            initial_position_y={ isMobile? 120 :5}
            initial_width     ={isMobile?  windowDimensions.width * 0.96 : windowDimensions.width*0.4 }
            initial_height    ={isMobile?windowDimensions.height - 150: windowDimensions.height - (70 +50)}
            zIndex            ={z_index_values["default_modal_details_feature"]}
           />
         )}
         {isWindowLayerDashboardOpen && dashboardsToShow.length > 0 && (
           <FloatingWindow
            title      ="Análisis de datos"
            content    ={dashboardsToShow.map((dashboardObj, index) => {
               console.log("Procesando dashboardObj", dashboardObj)
               return <div key={index}>
                 {<dashboardObj.dashboard layerGroup={dashboardObj.layerGroup}/>}
               </div>
             })}
             allow_maximize={true}
            onClose            ={handleCloseWindowDashboard}
            //initial_position_x  ={isMobile ?  windowDimensions.width * 0.004 : windowDimensions.width - (windowDimensions.width*0.4 + 50)}
            //initial_position_y  ={ isMobile? 120 :5}
            initial_position_x ={ isMobile? 15 : windowDimensions.width*0.4 }
            initial_position_y ={ isMobile? 120 :5}
            initial_width      ={isMobile?  windowDimensions.width * 0.96 : windowDimensions.width*0.4 }
            initial_height     ={isMobile?windowDimensions.height - 150: windowDimensions.height - (70 +50)}
            zIndex             ={z_index_values["default_floating_dashboard_window"]}
           />
         )}
        {isLegendOpen && <BaseLegend />}
        </div>
        </div>
       
    </>
}
export default Main;