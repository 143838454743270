import LayerGroup from "ol/layer/Group"; // Importamos LayerGroup
import Map from "ol/Map";
import View from "ol/View";
import { getTopLeft } from "ol/extent";
import { get } from "ol/proj";
import { TileGrid } from "ol/tilegrid";
import {
  replace_base_map,
  find_current_basemap_layer,
  get_default_base_map,
} from "./BaseMapsService";
// import Style from 'ol/style/Style';
// import Stroke from 'ol/style/Stroke';
// import { click } from 'ol/events/condition';
// import Select from 'ol/interaction/Select';
// import Fill from "ol/style/Fill";
// import CircleStyle from "ol/style/Circle";

import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Style, Icon } from 'ol/style';
import { z_index_values } from "../Commons/zIndexService";
import Legend from "ol-ext/control/Legend"
// Función para crear un marcador
export const createMarker = (coordinate) => {
  return new Feature({
    geometry: new Point(coordinate),
  });
};

// Función para crear una capa de marcadores (si no existe)
export const createMarkerLayer = () => {
  return new VectorLayer({
    zIndex:z_index_values["default_point_marker"],
    source: new VectorSource(),
    style: new Style({
      image: new Icon({
        src: process.env.PUBLIC_URL + "images/icons/marker-svgrepo-com.svg", //"/images/marker.png",  
        //src: process.env.PUBLIC_URL + "/images/marker.png",  
        anchor: [0.5, 1],
        anchorXUnits: "fraction",
        anchorYUnits: "fraction",
        scale: 0.08, // 0.04

        //scale: 0.04
      }),
    }),
  });
};

const add_mouse_click_event = (map, click_callback) => {
  console.log("Añadiendo evento de click en mapa.")
  let markerLayer = map.getLayers().getArray().find(layer => layer.get('name') === 'markerLayer');
  if (!markerLayer) {
    markerLayer = createMarkerLayer();
    markerLayer.set('name', 'markerLayer');
    map.addLayer(markerLayer);
  }

  map.on("singleclick", click_callback);
};
const add_visualization_layers_to_map = (map, layersGroups) => {
  // Iterar sobre las layersGroups para crear LayerGroups
  layersGroups.forEach((viz) => {
    // Crear el LayerGroup para cada visualización
    const layerGroup = new LayerGroup({
      visible:viz.visible,
      opacity : viz.opacity,
      layers: viz.layers.map((layerConfig) => {
        // Asignamos un id único a cada capa antes de agregarla al LayerGroup
        const tileLayer = layerConfig.layer;
        tileLayer.set("id", layerConfig.id); 
        tileLayer.set("name", layerConfig.name);
        tileLayer.set("opacity", layerConfig.opacity);
        tileLayer.set("visible", layerConfig.visible);
        tileLayer.set("zIndex", layerConfig.zIndex);
        
        
        console.log("tileLayer", tileLayer);
        return tileLayer;
      }),
    });
    layerGroup.set("id", viz.id); // Asignar el id del grupo
    layerGroup.set("name", viz.name); // Asignar el nombre del grupo
    map.addLayer(layerGroup); // Añadir el LayerGroup al mapa
  });
};

const get_inicial_map = (mapElementRef, center = [-3.70256, 40.4165], zoom= 7) => {
  const baseMapLayer = get_default_base_map();
  const initialMap = new Map({
    target: mapElementRef.current,
    layers: [baseMapLayer], // Inicialmente solo el mapa base
    view: new View({
      projection: "EPSG:4326",
      center: center,
      zoom:zoom,
    }),
    controls: [],
  });
  console.log("Mapa inicial", initialMap)
  //mapElementRef.current = initialMap
  return initialMap;
};

const get_epsg4326_projection_data_to_source = ()=>{
  var projection = get("EPSG:4326");
  var projectionExtent = projection.getExtent();
  var matrixIds = new Array(22);
  const tileGrid4326 = new TileGrid({
    origin: getTopLeft(projectionExtent),
    resolutions: [
      0.703125, 0.3515625, 0.17578125, 0.087890625, 0.0439453125,
      0.02197265625, 0.010986328125, 0.0054931640625, 0.00274658203125,
      0.001373291015625, 0.0006866455078125, 0.00034332275390625,
      0.000171661376953125, 0.0000858306884765625, 0.00004291534423828125,
      0.000021457672119140625, 0.000010728836059570313,
      0.000005364418029785156, 0.000002682209014892578,
      0.000001341104507446289, 0.0000006705522537231445,
      0.00000033527612686157227,
    ],
    matrixIds: matrixIds,
  });
  return {
      projection,
      projectionExtent,
      matrixIds,
      tileGrid4326,
  }
}

export {
  add_visualization_layers_to_map,
  add_mouse_click_event,
  get_inicial_map,
  get_epsg4326_projection_data_to_source
};
