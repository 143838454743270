import React from 'react';
import Notiflix from 'notiflix';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Box,
  Tooltip,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// Function to format float numbers
const formatFloat = (value, decimals = 2) => {
  if (typeof value === 'number' && !Number.isInteger(value)) {
    return new Intl.NumberFormat('es-ES', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true
    }).format(value).replace(/\./g, ' ').replace(/,/g, '.');
  }
  return value;
};

// Function to detect URLs
const isURL = (str) => {
  if (typeof str === 'string' || str instanceof String){
    return str.startsWith("https://");
  }
  return false;
};

const FeatureTableAccordion = (
  feature,
  title = 'Propiedades del Objeto',
  filterReplaceDict = null,
  styles = {},
  description = false,
  decimals = 2, // Added parameter for decimal adjustment
  just_table = false,
  backgroundColor = "#f0f0f0"
) => {
  if (!feature) {
    return <></>;
  }

  // Obtener las propiedades del feature
  let properties = {};
  if ('properties' in feature) {
    properties = feature['properties'];
  } else {
    properties = feature.getProperties();
  }

  // Aplicar filtro y reemplazo si filterReplaceDict está presente
  if (filterReplaceDict) {
    properties = Object.keys(properties).reduce((acc, key) => {
      // Mantener el orden de las propiedades
      if (filterReplaceDict.hasOwnProperty(key)) {
        const newKey = filterReplaceDict[key] || key; // Si hay un reemplazo, usarlo, si no, mantener la clave original
        acc[newKey] = properties[key]; // Mantener el valor
      }
      return acc;
    }, {});
  }

  const table = (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 400 }}>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                backgroundColor: 'var(--color-primary)',
                color: 'var(--color-primary-txt)',
                fontWeight: 'bold',
              }}
            >
              Propiedad
            </TableCell>
            <TableCell
              sx={{
                backgroundColor: 'var(--color-primary)',
                color: 'var(--color-primary-txt)',
                fontWeight: 'bold',
              }}
            >
              Valor
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(properties).map(([key, value], index) => {
            const formattedValue = formatFloat(value, decimals);
            const cellContent = isURL(formattedValue) ? (
              <Tooltip title="Click para ir a la URL">
                <a href={formattedValue} target='_blank' rel='noopener noreferrer'>Link</a>
              </Tooltip>
            ) : (
              <Typography sx={{ textOverflow: 'ellipsis', overflow: "hidden", whiteSpace: "nowrap", minWidth: "15rem", maxWidth: "70%", '&:hover': { textOverflow: "clip !important" } }}>
                {formattedValue}
              </Typography>
            );

            return (
              <TableRow
                key={index}
                sx={{
                  backgroundColor: index % 2 === 0 ? 'var(--color-bg-2)' : 'var(--color-bg-1)',
                  '&:hover': {
                    backgroundColor: '#44ff44',
                    color: "black"
                  },
                  color: "var(--color-secondary-txt)"
                }}
              >
                <TableCell>{key}</TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      position: 'relative',
                      '&:hover .copy-icon': {
                        opacity: 1,
                        backgroundColor: "white",
                        borderRadius: "50%"
                      },
                    }}
                  >
                    <Typography sx={{ flexGrow: 1 }}>
                      {cellContent}
                    </Typography>
                    <Tooltip title="Click para copiar">
                      <IconButton
                        className="copy-icon"
                        sx={{
                          position: 'absolute',
                          right: 0,
                          marginLeft: "1rem",
                          opacity: 0.5,
                          transition: 'opacity 0.3s',
                        }}
                        onClick={() => {
                          navigator.clipboard.writeText(formattedValue);
                          Notiflix.Notify.info("Valor copiado al portapapeles");
                        }}
                        size="small"
                      >
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (just_table ? table :
    <div style={{ marginBottom: '1rem' }}>
      <Accordion
        sx={{
          backgroundColor: backgroundColor,
          color: '#000',
          borderRadius: 2,
          '&:before': {
            display: 'none',
          },
          '& .MuiAccordionSummary-root': {
            borderRadius: 2,
          },
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{
          position: 'sticky', backgroundColor: backgroundColor, borderRadius: "1rem", border: "1px solid rgba(0, 0, 0, 0.4)",
          top: -15,
          zIndex: 42
        }}>
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {description ? description : null}
          {table}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export { FeatureTableAccordion };
