import React, { useContext } from 'react';
import { GeoapifyContext, GeoapifyGeocoderAutocomplete } from '@geoapify/react-geocoder-autocomplete';
//import 'react-geocode-autocomplete/build/react-geocode-autocomplete.css';
import { addMarkerToMap } from '../../../services/MapCore/MarkerService';
import AppContext from '../../Contexts/AppContext';
import "./MapSearcher.css"
import { containsCoordinate } from 'ol/extent';
import Notiflix from 'notiflix';
const GEOAPIFY_KEY = "4eb2e90e60de455bb2360adb3c89c15b";

function MapSearcher() {
  const {
    mapInstanceRef, 
    aoiExtent,
    setIsWindowFeatureDetailsOpen
  } = useContext(AppContext);

  const onPlaceSelect = (place) => {
    if(place?.properties == undefined){
      return
    }
    const lon = place?.properties?.lon;
    const lat = place?.properties?.lat;
    const street = place?.properties?.formatted;
    let isInside = containsCoordinate(aoiExtent, [lon, lat]);

    if (isInside) {
     // console.log("La coordenada está dentro del extent.");
    } else {

      Notiflix.Notify.warning("La ubicación introducida está fuera del área de estudio")
      console.log("Coordenadas deseadas:", place?.properties)
      return
    }
    // Centrar y acercar el mapa a la ubicación seleccionada
    mapInstanceRef.current.getView().animate({
      center: [lon, lat],
      zoom: 17,
      duration: 350,
    });

    // Añadir marcador al mapa
    addMarkerToMap(mapInstanceRef.current, [lon, lat]);
    setIsWindowFeatureDetailsOpen(false);
  };

  return (
    <GeoapifyContext apiKey={GEOAPIFY_KEY}>
      <div id="autocomplete" className="autocomplete-container"></div>
      <GeoapifyGeocoderAutocomplete
        placeholder="Escriba una dirección"
        lang="es"
        addDetails={false}
        placeSelect={onPlaceSelect}
      />
    </GeoapifyContext>
  );
}

export default MapSearcher;
