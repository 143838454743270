import { DefaultLegendClass } from "./DefaultLegendClass";

class ComponentLegend extends DefaultLegendClass{
    constructor(component, name = false){
        super()
        this.component = component
        this.name = name
    }
    get_legend() {
        return <>{this.component}</>;
      }
      get_name() {
        return this.name;
      }
}
export {ComponentLegend}