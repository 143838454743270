// data/toolbarItems.js
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import InfoIcon from '@mui/icons-material/Info';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { LayerSettingsComponent } from '../../Map/Layers/LayerSettings/LayerSettingsComponent';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import "./ToolbarItems.css"
export const toolbarItems = [
  // {
  //   id: 1,
  //   title: 'Inicio',
  //   icon: <HomeIcon />,
  //   component: <div>Contenido de Inicio</div>,
  // },
  // {
  //   id: 2,
  //   title: 'Ajustes',
  //   icon: <SettingsIcon />,
  //   component: <div>Contenido de Ajustes</div>,
  // },
  // {
  //   id: 3,
  //   title: 'Información',
  //   icon: <InfoIcon />,
  //   component: <div>Contenido de Información</div>,
  // },
  {
    id:1,
    title:"Capas",
    icon:<img  src='images/icons/Layers_dark.svg' style={{ width: 24, height: 24 }}  className=".transparent-header-icon" alt='Capas'/>,
    component:<LayerSettingsComponent/>,
    drawerWidth:"450px"
  },
  {
    id:2,
    title:"Dashboard",
    icon:<InsertChartIcon  />,
    action: () => {
      const event = new Event("dashboard-open-request");
      document.dispatchEvent(event);  
    },
  },
  {
    id: 4,
    position:"end",
    title: 'Cerrar sesión',
    icon: <ExitToAppIcon/>,
    action: () => {
      // Aquí puedes agregar la lógica de logout
      console.log('Cerrando sesión...');
      // Por ejemplo, redirigir al login
      window.location.href = '/login';
    },
  },
];
