


import PrintDialog from 'ol-ext/control/PrintDialog';
import { useContext, useEffect } from 'react';
import "ol/ol.css"
import "ol-ext/dist/ol-ext.css"
import 'ol-ext/control/PrintDialog.css';
import "./PrintControl.css"
import PrintIcon from '@mui/icons-material/Print';
import { IconButton } from '@mui/material';
import { jsPDF } from "jspdf";
import { saveAs } from 'file-saver';
import AppContext from '../../../../Contexts/AppContext';
import html2canvas from 'html2canvas';
const PrintControl = ({ map }) => {
    
  const {
    mapInstanceRef,
  } = useContext(AppContext);
    useEffect(() => {
      if (!map) return;
  
      // Crear el control PrintDialog
      const labels_es = {
        "title": "Imprimir",
        "orientation": "Orientación",
        "portrait": "Vertical",
        "landscape": "Horizontal",
        "size": "Tamaño",
        "custom": "Personalizado",
        "margin": "Margen",
        "scale": "Escala",
        "legend": "Leyenda",
        "north": "Norte",
        "mapTitle": "Títuilo del mapa",
        "saveas": "Guardar como...",
        "saveLegend": "Guardar leyenda...",
        "copied": "✔ Copiado en el portapapeles",
        "errorMsg": "Error durante la renderización...",
        "printBt": "Imprimir...",
        "clipboardFormat": "Guardar en el portapapeles...",
        "jpegFormat": "Guardar como jpeg",
        "pngFormat": "Guardar como png",
        "pdfFormat": "Guardar como pdf",
        "none": "Ninguno",
        "small": "Pequeño",
        "large": "Grande",
        "cancel": "Cancelar"
    }
    PrintDialog.addLang("es", labels_es)
      const printDialog = new PrintDialog({
        className: 'custom-print-button',
        lang:"es",
        title:"Cartografía - Solar",
        pdf:false,
        save:false,
        copy:false,
        map:map,
        saveAs:(e)=>{console.log("evento", e)},
       // immediate:true,
        orientation:"landscape",
        size: 'A4',
        
        
      });
      // printDialog.i18n("es");

    // PrintDialog.prototype.legendOptions = { off: 'Hide legend', on: 'Show legend' }; 
      // Añadir el control al mapa
      map.addControl(printDialog);
      

      
    // Evento que se dispara justo antes de la impresión
    printDialog.getPrintControl().on('show', function (e) {
      console.debig('show');
        if (e.canvas) {
            const canvas = e.canvas;
            const ctx = canvas.getContext('2d');
            
            // Obtener el elemento de la leyenda
            const legendElement = document.getElementById('legend');

            if (legendElement) {
                // Renderizar la leyenda en un canvas usando html2canvas
                html2canvas(legendElement).then(function (legendCanvas) {
                    // Posicionar la leyenda en el canvas del mapa
                    const x = 10; // posición horizontal
                    const y = canvas.height - legendCanvas.height - 10; // posición vertical
                    ctx.drawImage(legendCanvas, x, y);

                    // Continuar con la impresión
                    printDialog.printCanvas(canvas);
                });
            } else {
                // Si no hay leyenda, continuar con la impresión normal
                printDialog.printCanvas(canvas);
            }
        } else {
            console.error('No se pudo obtener el canvas del mapa.');
        }
    });

    // Evento que se dispara después de la impresión
    printDialog.on('printing', function () {
        console.log('printing');
        // Aquí puedes limpiar o actualizar el estado si es necesario
    });









      return () => {
        // Limpiar el control cuando el componente se desmonte
        map.removeControl(printDialog);
      };
    }, [map]); // Dependemos de que el mapa esté disponible
    const triggerPrintDialog = () => {
      // Buscar el botón dentro del PrintDialog usando la clase personalizada
      const printButton = document.querySelector('.custom-print-button button');
      if (printButton) {
        printButton.click(); // Simular un clic en el botón del PrintDialog
      } else {
        console.log("Botón de impresión no encontrado.");
      }
    };
    return <IconButton onClick={triggerPrintDialog}><PrintIcon className="bg-dark bg-hover-icon" style={{color:"white"}}/></IconButton>;
  };

export {PrintControl}