import { ComponentLegend } from "./ComponentLegend";
import "./GradientLegend.css"
class GradientComponentLegend extends ComponentLegend{
    constructor(title,  labelMin, labelMax){
        const component = <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
        <label>{labelMin}</label>
        <div style={{
            minWidth :"15px",
            width    :"20px",
            minHeight:"120px",
          }} className="gradient">
      
          </div>
          <label>{labelMax}</label>
          </div>
        super(component, title)
    }
}
export {GradientComponentLegend}