import React, { useContext } from "react";

// importing material UI components
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Footer.css";
import en from "./i18n/en";
import es from "./i18n/es";
import i18next from "i18next";
import LanguageSwitcher from "../../CustomComponents/LanguageSwitcher/LanguageSwitcher";
import AppContext from "../../Contexts/AppContext";
import { Tooltip } from "@mui/material";

i18next.addResourceBundle("en", "HEADER_TRANSLATION", en);
i18next.addResourceBundle("es", "HEADER_TRANSLATION", es);

export default function Footer({ highlightedElement, style }) {
  const { t } = useTranslation("HEADER_TRANSLATION");
  const navigate = useNavigate();
  const LOCAL_STORAGE_KEY = process.env.REACT_APP_LOCAL_STORAGE_KEY;
  // const logout = () => {
  //   const isAuthenticated = localStorage.removeItem(LOCAL_STORAGE_KEY);
  //   window.location.href = "/login";
  // };
  const getButtonClassName = (buttonName) => {
    return highlightedElement === buttonName
      ? "button-effect button-oval button-selected"
      : "button-effect button-oval";
  };
  const SOCIAL_MEDIA_AND_ICONS = [
    {
      label: "Linkdn",
      link: "https://www.linkedin.com/company/agforest/",
      icon: process.env.PUBLIC_URL + "/images/social_media/linkdn.png",
    },

    {
      label: "Twitter",
      link: "https://twitter.com/Agforest_",
      icon: process.env.PUBLIC_URL + "/images/social_media/twitter_x.png",
    },
    // {
    //   label: "Web",
    //   link: "https://www.agforest.es",
    //   icon: process.env.PUBLIC_URL + "/images/social_media/web.png",
    // },
  ];
  return (
    <footer className="footer" style={{ ...style }}>
      <div className="footer_row">
        {/* Links terms and conditions */}
        <div className="footer_column" style={{ textAlign: "left" }}>
          <a
            href="https://www.agforest.ai/legal-disclaimer"
            style={{ color: "var(--color-primary-txt" }}
            target="_blank"
          >
            <label className="footer_label">{t("txt_footer_legal")}</label>
          </a>
          <a
            target="_blank"
            href="https://www.agforest.ai/politica-y-cookies"
            style={{ color: "var(--color-primary-txt" }}
          >
            <label className="footer_label">{t("txt_footer_privacity")}</label>
          </a>
        </div>

        {/* Social media */}
        <div className="footer_column" style={{ textAlign: "right" }}>
          {/* <label style={{ color: "var(--color-primary-txt)", marginBottom: "1rem" }}>
            Links a redes sociales:
          </label> */}
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              height: "3rem",
            }}
          >
            <a
              style={{
                color: "var(--color-primary-txt)",
                height: "100%",
                cursor: "pointer",
              }}
              target="_blank"
              href="https://www.agforest.ai"
            >
              <img
                src={
                  process.env.PUBLIC_URL + "/images/social_media/agforest.png"
                }
                style={{
                  marginBottom: "1rem",
                  height: "2.2rem",
                  maxHeight: "2.5rem",
                }}
              />
            </a>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: "1rem",
            }}
          >
            {SOCIAL_MEDIA_AND_ICONS.map((e) => {
              return (
                <Tooltip title={e.label}>
                  <a
                    style={{
                      color: "var(--color-primary-txt)",
                      height: "1.5rem",
                      cursor: "pointer",
                      marginRight: "1rem",
                    }}
                    href={e.link}
                    target="_blank"
                  >
                    <img src={e.icon} style={{ height: "1.5rem" }} />
                  </a>
                </Tooltip>
              );
            })}
          </div>
        </div>
      </div>
      <div style={{ display: "flex", width: "100%" }}></div>
    </footer>
  );
}
