import LayerGroupSettings from "../LayerGroupSettings";
import { createHeatmapLayer } from "./Layers/SolarHeatMap";
import { createSolarLayerWMS } from "./Layers/SolarWMS";
import {FeatureTableAccordion} from "../../../../../services/Commons/Feature/FeatureTableAccordion";
import { legendWMS } from "../../../Legends/WMSLegend";
import GraphSolar from "./Components/GraphSolar";
import DictionaryTabs from "../../../../CustomComponents/DictionaryTabs/DictionaryTabs";
import { Accordion, AccordionDetails,Typography,  AccordionSummary, Divider, Paper } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Notiflix from "notiflix";
import { replaceLayerInGroupById } from "../../../../../services/MapCore/LayerGroupService";
import { FilterSolar } from "./Filters/FilterSolar";
import { GraphAmortizationComponent } from "./Components/GraphAmortizacion";
import { ComponentLegend } from "../../../Legends/ComponentLegend";
import { GradientComponentLegend } from "../../../Legends/GradientLegend";
import { z_index_values } from "../../../../../services/Commons/zIndexService";
import { DashboardSolar } from "./Dashboard/DashboardSolar";

class LayerGroupSolar extends LayerGroupSettings {
  constructor(map=null) {
    super();
    this.id = "group-solar";
    this.name = "Potencialidad solar";
    this.map = map
    this.has_filter = true;
    this.getFilter = this.get_filter
    this.has_dashboard = true;
    this.dashboard = DashboardSolar
    this.selectedFeatures = []
    this.headMapLegend = new GradientComponentLegend("Mapa de calor coste / oportunidad", 
      "Más relevante",
      "Menos relevante"
    )
    this.legends = [
      new legendWMS("https://api.asbestos.ai/geoserver/agforest/wms", "agforest:poc_solar_amortization", "Coste/oportunidad potencial solar", false, "", false),
      this.headMapLegend 
    ]
    //this.layerVFS    = createSolarComercialCloroplethNumericLayer(this.defaultLayer, this.onMapClick, this.map);
    this.filters = [
      // { key: 'Uso', values: [
      //   "Agrícola",
      //   "Oficinas",
      //   "Retail",
      //   "No consta",
      //   "Servicios Públicos",
      //   "Industrial",
      //   "Residencial"], operation: 'OR' },
      //  { key: 'Titular', values: ['Privado',"Público", "Municipal"], operation: 'OR' },
      //  { key: 'Nivel_ries', values: ['Alto',"Medio", "Leve", "Muy Alto"], operation: 'OR' },
      //  { key: 'potencial_comercial_solar_norm', values: [">=0"], operation: 'OR' }

    ];
    this.currentStyleId = "potencial_comercial_solar_norm_cuadrado"
    this.layerWMS     = createSolarLayerWMS(this.defaultLayer, this.onMapClick, this.map, this.handleChangeStyle,  this.currentStyleId , this.filters);
    const defaultHeat = this.defaultLayer
    defaultHeat.zIndex = z_index_values["layer-susceptibility-heatmap"]
    defaultHeat.opacity = 0.7
    this.heatmapLayer = createHeatmapLayer(defaultHeat, this.onMapClick, this.map, this.filters);
    //this.handleChangeStyle = this.handleChangeStyle.bind(this);
    this.layers.push(
     // this.layerVFS,
      this.heatmapLayer,
      this.layerWMS,
    );
  };

  handleChangeStyle = (ev, layer_id) =>{
    console.log("this", this)
    const layers = this.layers.filter(old_layer=> old_layer.id == layer_id);
    const layer = layers[0];
    const new_style_id = ev.target.value;
    this.currentStyleId = new_style_id;
    console.log("Buscando style id", new_style_id, "en ", layer.styles_aviables )
    const filtered_styles = layer.styles_aviables.filter(
      (element) => element.id == new_style_id
    );
    if (filtered_styles.length <= 0) {
      Notiflix.Notify.warning("Estilo no disponible");
      return;
    }
    const new_style = filtered_styles[0];
    console.log("Añadiendo nuevo estilo", new_style)
    const new_layer = createSolarLayerWMS(
      this.defaultLayer,
      this.onMapClick,
      this.map,
      this.handleChangeStyle,
      new_style["id"],
      this.filters
    );
    const tileLayer = new_layer.layer;
    tileLayer.set("id", new_layer.id); 
    tileLayer.set("name", new_layer.name);
    tileLayer.set("opacity", new_layer.opacity);
    tileLayer.set("visible", new_layer.visible);
    tileLayer.set("zIndex", new_layer.zIndex);
    
    
    replaceLayerInGroupById(this.map, this.id, new_layer.id, tileLayer);
    //layer = new_layer
    this.layers = this.layers.filter(old_layer=> old_layer.id != layer_id);
    console.log("Añadiendo leyenda...", new_style["id"], new_style["label"])
    this.legends = [
      new legendWMS("https://api.asbestos.ai/geoserver/agforest/wms", "agforest:poc_solar_amortization", new_style["label"], false, "", new_style["id"]),
      this.headMapLegend 
    ]

    this.layers.push(new_layer);
  };

  callback_change_filter = (new_filters)=>{
    // document.addEventListener("update-filter-"+layerGroup.id,loadFeatures() )
      
      this.filters = new_filters
      
      console.log("Actualizando mapa de cloropletas con nuevos filtros", new_filters)

      const new_layer_wms     = createSolarLayerWMS(this.layerWMS, this.onMapClick, this.map, this.handleChangeStyle,  this.currentStyleId , this.filters);
      this.layerWMS = new_layer_wms;
      
      const tileLayer_wms = new_layer_wms.layer;
      tileLayer_wms.set("id"     , new_layer_wms.id); 
      tileLayer_wms.set("name"   , new_layer_wms.name);
      tileLayer_wms.set("opacity", new_layer_wms.opacity);
      tileLayer_wms.set("visible", new_layer_wms.visible);
      tileLayer_wms.set("zIndex" , new_layer_wms.zIndex);
      replaceLayerInGroupById(this.map, this.id, new_layer_wms.id, tileLayer_wms);

      // this.layers = this.layers.filter(old_layer=> old_layer.id != this.layerWMS.id);
      // this.layers.push(new_layer);
      
      console.log("Actualizando mapa de calor con nuevos filtros", new_filters)
      const new_layer_heat     = createHeatmapLayer(this.heatmapLayer, this.onMapClick, this.map, this.filters);
      this.heatmapLayer =   new_layer_heat;
        
      const tileLayer_heat = new_layer_heat.layer;
      tileLayer_heat.set("id"     , this.heatmapLayer.id); 
      tileLayer_heat.set("name"   , tileLayer_heat.name);
      tileLayer_heat.set("opacity", this.heatmapLayer.opacity);
      tileLayer_heat.set("visible", this.heatmapLayer.visible);
      tileLayer_heat.set("zIndex" , this.heatmapLayer.zIndex);
      replaceLayerInGroupById(this.map, this.id, new_layer_heat.id, tileLayer_heat);

      // this.layers = this.layers.filter(old_layer=> old_layer.id != this.heatmapLayer.id);
      // this.layers.push(new_layer_heat);
      
      console.log("Mapa de calor actualizado")
      this.layers = [new_layer_heat, new_layer_wms]
      const event = new Event("update-filter-"+this.id);
      document.dispatchEvent(event); 
  // this.heatmapLayer = 
  }
  get_filter(){
    return <><FilterSolar callback={this.callback_change_filter} filters={this.filters}/></>
  };
  async onMapClick(evt, _map) {
    const map = _map // this.map
    console.log("Map click event group solar", _map, this.map)
    //const featuresWMS = [];
    // await this.getFeatureInfoOnClickWMS(evt, map, tsusceptibilityBuildingLayerWMS.layer, "agforest:poc_solar_amortization")
    // const layer_used = this.layerVFS.layer
    // const data = solarComercialCloroplethOnClickGetComponent(evt, map,layer_used)
    const replacements_inmueble = {
      "RefCatast"  : "Referencia catastral",
      "fx_creation": "Fecha de creación",
      "Uso"        : "Tipo de uso de la edificación",
      "Titular"    : "Titular",
      "Provincia"  : "Provincia",
      "Municipio"  : "Municipio",
      "CodINE"     : "Código INE del municipio",
      "Fecha_cons" : "Fecha de construcción del edificio",
      "M2_catast"  : "Superficie construida (m2)",
      "Link_catas" : "URL del inmueble (Catastro)",
      "Link_image" : "URL con la imagen del inmueble (Catastro)",
      "Link_maps"  : "URL a google maps",

     }
     const replacements_asbestos = {
      "Nivel_ries":"Nivel de riesgo de contener amianto",
      "Prioridad" :"Prioridad de retirada del amianto calendarizada",
      "Retirada_c":"Coste total de retirada estimado (€)",
      "Cubasic_C" :"Coste del reemplazo de la cubierta por una económica",
      "Libasic_C" :"Coste de la licencia para el reemplazo de la cubierta económica",
      "Cunormal_C":"Coste del reemplazo de la cubierta residencial",
      "Linormal_C":"Corte de la licencia para el reemplazo de la cubierta residencial",
     }
     const replacements_solar = {
      "solar_amortizacion_years"       :"Tiempo estimado de amortización (años)",
      "solar_amortizacion_normal_years":"Tiempo estimado de amortización reemplazando la cubierta de amianto por una residencial (años)",
      "solar_amortizacion_basic_years" :"Tiempo estimado de amortización reemplazando la cubierta de amianto por una económica (años)",
      "Potencia_instalada_kWp"         : "Potencia propuesta para la instalación de paneles solares (kWp)",
      "n_modulos"                      : "Número de módulos a instalar",
      "potencia_modulos"               :"Potencia individual de cada módulo",
      "ratio_kWp_m2"                   : "Ratio de potencia / superficie (kWp/m2)",
      "Coste_€_kWp"                    : "Ratio precio / potencia (€/kWp)",
      "superficie_fv_percent"          : "Porcentaje de la superficie aprovechado para la instalación de placas",
      "SolAreaPnl"                     : "Area utilizada para la instalación de paneles",
      "SolEnergia"                     : "Energía anual estimada",
      "SolCO2"                         :"Estimación de las toneladas de CO2 evitadas anualmente",
      "SolAhorro"                      : "Estimación del ahorro anual gracias a esta propuesta",
      "coste_solar_mantenimiento_anual":"Estimación del coste anual por mantenimiento de las placas (€)",
      "coste_solar_inicial_total"      :"Estimación de la inversión inicial necesaria para realizar la instalación (€)",


     }


    const featuresWMS  =  await this.getFeatureInfoOnClickWMS(evt, map, this.layerWMS.layer, "agforest:poc_solar_amortization")
    if(featuresWMS.length <=0){
      return false
    }
    console.log("Features encontradas en solar:", featuresWMS)
    let properties = {};
    let description = false


    // const data_catastro = featuresWMS.map(f=>{
    //   if ("properties" in f) {
    //     properties = f["properties"];
    //   } else {
    //     properties = f.getProperties();
    //   }
      
    //   if(Object.keys(properties).includes("Link_image")){
    //     description = <a  href={properties["Link_image"]} target='blank'><img style={{width: "100%", maxWidth:"600px",borderRadius: "10px",height:"100%",margin:"1rem",   objectFit:"cover"}} src={properties["Link_image"]}/> </a>
    //   }
    //   return FeatureTableAccordion(f, "Datos de la edificación", replacements_inmueble, {}, description)
    // })
    
    const data_catastro_img = featuresWMS.map(f=>{
      if ("properties" in f) {
        properties = f["properties"];
      } else {
        properties = f.getProperties();
      }
      
      if(Object.keys(properties).includes("Link_image")){
        description = <Paper><a  href={properties["Link_image"]} target='blank'><img style={{width: "100%", maxWidth:"600px",borderRadius: "10px",height:"100%",margin:"1rem",   objectFit:"cover"}} alt="Imagen del inmueble" src={properties["Link_image"]}/> </a></Paper>
      }
      return description
    })

    const data_catastro_table = featuresWMS.map(f=>{
      return FeatureTableAccordion(f, "Datos de la edificación", replacements_inmueble, {}, false, 2, true)
    })
    const data_asbestos        = featuresWMS.map(f=>FeatureTableAccordion(f, "- Análisis de amianto", replacements_asbestos, {}, false, 2, true,))
    //const data_solar           = featuresWMS.map(f=><>{FeatureTableAccordion(f, "Datos solares",replacements_solar, {}, <div style={{paddingBottom:"3rem", paddingTop:"1rem"}}><TabSolar feature={f}/></div>)}</>)//replacements_solar))
    const data_solar_accordeon = featuresWMS.map(f=><>{FeatureTableAccordion(f, "Datos solares",replacements_solar, {}, false, 2, true)}</>)//replacements_solar))
    const data_solar_graph     = featuresWMS.map(f=> <Paper style={{paddingBottom:"3rem", paddingTop:"1rem"}}><GraphSolar feature={f}/></Paper>)
    const data_solar_amortitation_graph = featuresWMS.map(f=>{
      let properties = {};
      if(!f){
        console.log("no hay feature", f)
      }
      console.log("Feature", f)
      if ('properties' in f) {
        properties = f['properties'];
      } else if(f.getProperties){
        properties = f.getProperties();
      }
      if(properties && Object.keys(properties).length>0){
        return <Paper style={{paddingBottom:"3rem", paddingTop:"1rem", width:"100%"}}>
        <GraphAmortizationComponent maintenanceRateCost={properties["coste_solar_mantenimiento_anual"]} annualSavings={properties["SolAhorro"]} initialCost={properties["coste_solar_inicial_total"]} amortization_time={properties["solar_amortizacion_years"]} />
      </Paper>
      }
      
    })
     //replacements_solar))</div>

// TODO - Pendiente de meter los acordeones por sí solos en un componente y sacarlos de aquí para simplificar.
    return (<>
    <Divider  variant="middle"/>
      <div style={{paddingLeft:"2rem", paddingTop:"2rem"}}>
        {/* {data_catastro} */}
        <div style={{ marginBottom: '1rem'}}>
      <Accordion
        sx={{
          backgroundColor: "#C8EAD3", //'#f0f0f0',
          color: '#000',
          borderRadius: 2,
          '&:before': {
            display: 'none',
          },
          '& .MuiAccordionSummary-root': {
            borderRadius: 2,
          },
          
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{position: 'sticky',backgroundColor: "#C8EAD3", borderRadius:"1rem", border:"1px solid rgba(0, 0, 0, 0.4)",
          top: -15,
          zIndex: 42}}
          style={{overflowX:"auto !important"}}
          >
          <Typography>- Datos de la edificación{data_catastro_table.length > 1 ? " (" + data_catastro_table.length + " elementos)":""}</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <DictionaryTabs tabsData={{
          "Tabla" : data_catastro_table,
          "Imagen":data_catastro_img
        }
        }/>
        </AccordionDetails>
        </Accordion>
      </div>


      <Divider  variant="middle"/>
        {/* {data_catastro} */}
        <div style={{ marginBottom: '1rem'}}>
      <Accordion
        sx={{
          backgroundColor:"#e5e5ff", //'#f0f0f0',
          color: '#000',
          borderRadius: 2,
          '&:before': {
            display: 'none',
          },
          '& .MuiAccordionSummary-root': {
            borderRadius: 2,
          },
          
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{position: 'sticky',backgroundColor: "#e5e5ff", borderRadius:"1rem", border:"1px solid rgba(0, 0, 0, 0.4)",
          top: -15,
          zIndex: 42}}
          style={{overflowX:"auto !important"}}

          >
          <Typography>- Datos de amianto{data_asbestos.length > 1 ? " (" + data_asbestos.length + " elementos)":""}</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <DictionaryTabs tabsData={{
          "Tabla" : data_asbestos,
          "Imagen":data_catastro_img
        }
        }/>
        </AccordionDetails>
        </Accordion>
        </div>


        

        {/* {data_solar} */}

    <div style={{ marginBottom: '1rem' }}>
      <Accordion
        sx={{
          backgroundColor: '#FFFDDC',
          color: '#000',
          borderRadius: 2,
          '&:before': {
            display: 'none',
          },
          '& .MuiAccordionSummary-root': {
            borderRadius: 2,
          },
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{position: 'sticky',backgroundColor: "#C8EAD3", borderRadius:"1rem", border:"1px solid rgba(0, 0, 0, 0.4)",
          top: -15,
          zIndex: 42}} 
          style={{overflowX:"auto !important"}}
          >
          <Typography>- Datos solares{data_solar_accordeon.length > 1 ? " (" + data_solar_accordeon.length + " elementos)":""}</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <DictionaryTabs tabsData={{
          "Tabla" : data_solar_accordeon,
          "Gráfica energía":data_solar_graph,
          "Gŕafica amortización":data_solar_amortitation_graph

        }
        }/>
        </AccordionDetails>
        </Accordion>
        </div>
      </div>
      
      </>
    );
  };
}
export default LayerGroupSolar;
