// Variable global para alternar entre filtrado local y filtrado en el servidor

import VectorSource from "ol/source/Vector";
import GeoJSON from 'ol/format/GeoJSON.js';
import { Cluster } from "ol/source";
import { getCenter } from "ol/extent";
import { Circle, Point } from "ol/geom";
import Heatmap from 'ol/layer/Heatmap';
import Notiflix from "notiflix"
// Importa tus funciones y servicios existentes
import { interpolateColor } from "../../../../../../services/Commons/ColorService";
import { mediaTop10Valores } from "../../../../../../services/Commons/CommonFunctions";
import { z_index_values } from "../../../../../../services/Commons/zIndexService";
import { buildCQLFilter } from "../../../../../../services/MapCore/MapCQLFilterService";
import Collection from 'ol/Collection.js';
import { replaceLayerInGroupById } from "../../../../../../services/MapCore/LayerGroupService";

const USE_LOCAL_FILTERING = true; // Cambia a false para usar el filtrado en el servidor

// Función para aplicar filtros localmente
function applyFilters(features, filters) {
  return features.filter(feature => {
    //console.log("Filtering feature", feature)
    return filters.every(filter => {
      const { key, values, operation } = filter;
      const featureValue = feature.getProperties()[key];

      if (values.length > 1) {
        if (operation === 'AND') {
          return values.every(value => evaluateFilter(featureValue, value));
        } else if (operation === 'OR') {
          return values.some(value => evaluateFilter(featureValue, value));
        }
      } else {
        const value = values[0];
        return evaluateFilter(featureValue, value);
      }
    });
  });
}

function evaluateFilter(featureValue, valueExpression) {
  // valueExpression puede ser '>0', '<=100', '=someValue', 'someValue'
  if (valueExpression.includes('>') || valueExpression.includes('<') || valueExpression.includes('=')) {
    const match = valueExpression.match(/([><=]=?)(.+)/);
    if (match) {
      const operator = match[1];
      let value = match[2].trim();
      const numericValue = parseFloat(value);
      const featureNumericValue = parseFloat(featureValue);
      
      switch (operator) {
        case '>':
          return featureNumericValue > numericValue;
        case '>=':
          return featureNumericValue >= numericValue;
        case '<':
          return featureNumericValue < numericValue;
        case '<=':
          return featureNumericValue <= numericValue;
        case '=':
          if (value == "true"){
            value = true;
          }else if(value == "false"){
            value = false
          }
          return featureValue == value;
        default:
          return false;
      }
    } else {
      return false;
    }
  } else {

    return featureValue == valueExpression;
  }
}

const createHeatmapLayer = (defaultLayer, onMapClick, map, filters = []) => {
  const heatMapLayer = Object.assign({}, defaultLayer);
  heatMapLayer.id = "layer-poc_solar_amortization-heat";
  heatMapLayer.map = map;
  heatMapLayer.name = "Mapa de calor del coste/oportunidad";
  heatMapLayer.zIndex = defaultLayer.zIndex;
  heatMapLayer.onClickGetInfo = onMapClick;

  // Crea la capa Heatmap con una fuente vacía inicialmente
  heatMapLayer.layer = new Heatmap({
    source:  new Cluster({
      geometryFunction: feature => {
        try {
          return new Point(getCenter(feature.getGeometry().getExtent()));
        } catch {
          return feature.getGeometry();
        }
      },
      distance: 30,
      minDistance: 25,
      source: new VectorSource({
        format: new GeoJSON(),
        features:[]
      }),
    }), // Se establecerá más tarde
    blur: 50,
    radius: 55,
    opacity: defaultLayer.opacity,
    zIndex: defaultLayer.zIndex,
    renderMode: "image",
    weight: function (clusterFeature) {
      const clusteredFeatures = clusterFeature.get('features');
      let meanValue = 0;
      if (clusteredFeatures && clusteredFeatures.length > 0) {
        meanValue = mediaTop10Valores(clusteredFeatures, 'potencial_comercial_solar_norm');
      }
      meanValue = meanValue * 1;
      const r = Math.max(0, Math.min(1, meanValue));
      return r;
    },
  });

  // Añade la capa al mapa
  //map.addLayer(heatMapLayer.layer);

  if (USE_LOCAL_FILTERING) {
    if (!heatMapLayer.featuresData) {
      // Datos no cargados, fetch inicial
      Notiflix.Loading.circle("Cargando mapa...");
      const url =
        'https://api.asbestos.ai/geoserver/ows?' +
        'service=WFS&' +
        'version=2.0.0&' +
        'request=GetFeature&' +
        'typename=agforest:poc_solar_amortization&' +
        'outputFormat=application/json&' +
        'srsname=EPSG:4326';
      
      fetch(url)
        .then(response => response.json())
        .then(data => {
          // Parsear los datos GeoJSON
          const geojsonFormat = new GeoJSON();
          const features = geojsonFormat.readFeatures(data);
          heatMapLayer.featuresData = features//.map(f=>f.getProperties());
          //console.log("Features data obtenidos:", heatMapLayer.featuresData)
          // Aplicar filtros y actualizar la fuente de la capa
          
          updateLayerSource(heatMapLayer, filters);
        })
        .catch(error => {
          console.error('Error al obtener los datos:', error);
        }).finally(()=>{
          Notiflix.Loading.remove();
        });
    } else {
      // Datos ya cargados, aplicar filtros
      updateLayerSource(heatMapLayer, filters);
    }
  } else {
    // Modo original: filtrado en el servidor
    const cqlFilter = buildCQLFilter(filters);

    const base_source = new VectorSource({
      format: new GeoJSON(),
      url: function (extent) {
        return (
          'https://api.asbestos.ai/geoserver/ows?' +
          'service=WFS&' +
          'version=2.0.0&' +
          'request=GetFeature&' +
          'typename=agforest:poc_solar_amortization&' +
          'outputFormat=application/json&' +
          'srsname=EPSG:4326&' +
          'CQL_FILTER=' + encodeURIComponent(cqlFilter)
        );
      },
    });

    const cluster_source = new Cluster({
      geometryFunction: feature => {
        try {
          return new Point(getCenter(feature.getGeometry().getExtent()));
        } catch {
          return feature.getGeometry();
        }
      },
      distance: 30,
      minDistance: 25,
      source: base_source,
    });

    // Establecer la fuente de la capa Heatmap
    
    heatMapLayer.layer.setSource(cluster_source);
    replaceLayerInGroupById(map, "group-solar", heatMapLayer.layer.id, heatMapLayer.layer);
  }

  return heatMapLayer;
};

// Función para actualizar la fuente de la capa cuando se aplican filtros
function updateLayerSource(heatMapLayer, filters) {
  const features = heatMapLayer.featuresData;
  heatMapLayer.filteredFeatures = applyFilters(features, filters);
  //console.log("Valores filtrados", heatMapLayer.filteredFeatures)
  // Crear VectorSource con las features filtradas
  // const base_source = new VectorSource({
  //   format: new GeoJSON(),
  //   features: filteredFeatures,
  // });

  // // Crear fuente de clúster
  // const cluster_source = new Cluster({
  //   geometryFunction: feature => {
  //     try {
  //       return new Point(getCenter(feature.getGeometry().getExtent()));
  //     } catch {
  //       return feature.getGeometry();
  //     }
  //   },
  //   distance: 30,
  //   minDistance: 25,
  //   source: base_source,
  // });

  // Establecer la nueva fuente en la capa Heatmap
  heatMapLayer.layer.getSource().getSource().clear();
  heatMapLayer.layer.getSource().getSource().addFeatures(heatMapLayer.filteredFeatures);
  //replaceLayerInGroupById(heatMapLayer.map, "group-solar", heatMapLayer.layer.id, heatMapLayer.layer);
}

export { createHeatmapLayer };
