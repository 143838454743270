import React from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { IconButton, Tooltip } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import Notiflix from 'notiflix';
const ExportExcel = ({ data, fileName="Datos" }) => {
  const exportToExcel = () => {
    Notiflix.Loading.circle("Generando archivo...")
    // Si el array está vacío, no hacer nada
    if (!data || data.length === 0){
      Notiflix.Loading.remove();
      return;
    } 

    // Crear un nuevo libro de trabajo
    const wb = XLSX.utils.book_new();

    // Extraer las keys del primer objeto como encabezados
    const headers = Object.keys(data[0]);

    // Convertir el array de objetos a un formato adecuado para la hoja de cálculo
    const wsData = [headers, ...data.map(obj => headers.map(header => obj[header]))];

    // Crear una hoja de trabajo a partir de los datos
    const ws = XLSX.utils.aoa_to_sheet(wsData);

    // Agregar la hoja de trabajo al libro de trabajo
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Generar el archivo Excel
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Guardar el archivo con FileSaver
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(blob, `${fileName}.xlsx`);
    Notiflix.Loading.remove();
  };

  return (
    <Tooltip title="Descarga de excel">
    <IconButton onClick={exportToExcel}>
        <DownloadIcon style={{color:"black"}}/>
    </IconButton>
    </Tooltip>

  );
};

export default ExportExcel;
