import { useContext, useEffect } from "react";
import AppContext from "../../../../Contexts/AppContext";
import { useMap } from "../../../MapProvider";
import { IconButton } from "@mui/material";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { z_index_values } from "../../../../../services/Commons/zIndexService";

const ZOOM_ANIMATION_DURATION = 250
const ZoomControl = ({map})=>{
    // const {map} = useContext(AppContext)

    let handleZoomOut = () => {
        //console.log("Zoom Out ", map, map )
        if(map  ){
            const view = map.getView();
            const zoom = view.getZoom();
            //view.setZoom(zoom - 1);
            view.animate({zoom:zoom-1,
                duration: ZOOM_ANIMATION_DURATION,
            })
        }
      };
      let handleZoomIn = () => {
        //console.log("Zoom in ", map )
        if(map  ){
            const view = map.getView();
            const zoom = view.getZoom();
            //view.setZoom(zoom + 1);
            view.animate({zoom:zoom+1,
                duration: ZOOM_ANIMATION_DURATION,
            })

        }
      };

      return <div  className="bg-dark" style={{zIndex:z_index_values["control_map_zoom"], display:"flex", gap:"5px"}}>
      <IconButton className="bg-dark-svg" onClick={handleZoomOut}><ZoomOutIcon/></IconButton>
      <IconButton className="bg-dark-svg" onClick={handleZoomIn}><ZoomInIcon/></IconButton>
      </div>
}

export default ZoomControl