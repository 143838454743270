
const z_index_values = {
    "default_legend":30,
    "default_floating_window":41,
    "default_floating_dashboard_window":50,
    "default_modal_details_feature":40,
    "default_point_marker":80,

    "control_map_zoom":50,
    "control_map_basemap_selector":50,
    "layer_asbestos_susceptibility_building":41,
    "layer-susceptibility-heatmap-grilla":40,
    "layer-susceptibility-heatmap":40,
    "layer-poc_solar_amortization-heat":41,
    "layer-poc_solar_amortization-building":41,
}



export {z_index_values}