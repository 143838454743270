import React, { useContext } from 'react';
import { useMap } from '../MapProvider'; // Este hook te ayuda a acceder al mapa desde cualquier componente
import AppContext from "../../Contexts/AppContext"
import ZoomControl from './Components/ZoomControl/Zoom';
import "./MapControls.css"
import BaseLayerSelector from './Components/BaseLayerSelector/BaseLayerSelector';
import { PrintControl } from './Components/PrintControl/PrintControl';
// Componente para el zoom in

// Componente para cambiar de capas (puedes personalizarlo según las capas que tengas)
// const Layers = () => {
//     const {mapInstanceRef} = useContext(AppContext);

//   const handleLayerToggle = () => {
//     const layers = mapInstanceRef.current.getLayers().getArray();
//     layers.forEach(layer => {
//       layer.setVisible(!layer.getVisible());
//     });
//   };

//   return <button onClick={handleLayerToggle}>Toggle Layers</button>;
// };

// Componente personalizado para controles adicionales
const MyCustomControl = () => {
  // Aquí puedes implementar cualquier lógica adicional para controles personalizados
  return <button onClick={() => alert('Custom control activated')}>My Custom Control</button>;
};

// El panel de control que contiene todos los botones
const ControlPanel = (map) => {
  return (

    <div className="control-panel">
      <BaseLayerSelector map={map}/>
      <PrintControl map={map}/>
      <ZoomControl map={map}/>
      {/* <MyCustomControl /> */}
      
    </div>
  );
};

export { ControlPanel };
