import { useState, useContext, useEffect } from 'react';
import { Paper, Select, MenuItem, Button } from '@mui/material';
import AppContext from "../../../Contexts/AppContext";
import { DashboardButton } from './LayerDashboardButton/LayerDashboardButton';

const LayerFilterSelectorComponent = () => {
    const {
        layersGroups

    } = useContext(AppContext);

    // Estado inicial basado en layersGroups
    const [selectedLayer, setSelectedLayer] = useState(null);

    useEffect(() => {
        const firstLayerWithFilter = layersGroups.find(layer => layer.has_filter === true);
        if (firstLayerWithFilter) {
            setSelectedLayer(firstLayerWithFilter);
        }
    }, [layersGroups]);

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <h4>Control de filtrado de datos</h4>
            {layersGroups.filter((e) => e.has_filter === true).length > 0 ? (
                <div style={{width:"100%", display:"flex", flexDirection:"column", gap:"10px"}}>
                    <Paper style={{width:"100%"}}>
                        <Select
                            style={{ width: "100%" }}
                            onChange={(e) => {
                                e.preventDefault();
                                const layerGroupSelected = layersGroups.filter(layer => layer.id === e.target.value)[0];
                                setSelectedLayer(layerGroupSelected);
                            }}
                            value={selectedLayer ? selectedLayer.id : ''}
                        >
                            {layersGroups
                                .filter((layer) => layer.has_filter === true)
                                .map((layerGroup) => (
                                    <MenuItem key={layerGroup.id} value={layerGroup.id}>
                                        {layerGroup.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </Paper>
                    {/* {selectedLayer && selectedLayer.has_dashboard && <DashboardButton selectedLayer={selectedLayer}/>} */}
                    {/* {selectedLayer && selectedLayer.getFilter && <label>{selectedLayer.name}</label>} */}
                    {selectedLayer && selectedLayer.getFilter && <>{selectedLayer.getFilter()}</>}
                </div>
            ) : (
                <Paper sx={{ padding: "1rem" }}>No hay filtros disponibles</Paper>
            )}
        </div>
    );
};

export { LayerFilterSelectorComponent };
