import React, { useEffect, useRef, useState } from 'react';
import {
  Paper,
  ToggleButtonGroup,
  ToggleButton,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Slider,
  Typography,
  Divider,
  checkboxClasses,
  styled,
} from '@mui/material';
import './FilterSolar.css';
const CustomSlider = styled(Slider)(({ theme }) => ({
  color: 'var(--color-primary)',
  height: 3,
  padding: '13px 0',
  '& .MuiSlider-thumb': {
    height: 27,
    width: 27,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
    },
    '& .airbnb-bar': {
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  '& .MuiSlider-track': {
    height: 3,
  },
  '& .MuiSlider-rail': {
    color: 'var(--color-primary-light-50)',
    opacity: 1,
    height: 3,
    ...theme.applyStyles('dark', {
      color: '#bfbfbf',
      opacity: undefined,
    }),
  },
}));
const FilterSolar = ({callback, filters}) => {
  // const [filters, setFilters] = useState([
  //   { key: 'Uso', values: [
  //     "Agrícola",
  //     "Oficinas",
  //     "Retail",
  //     "No consta",
  //     "Servicios Públicos",
  //     "Industrial",
  //     "Residencial"], operation: 'OR' },
  //    { key: 'Titular', values: ['Privado',"Público", "Municipal"], operation: 'OR' },
  //    { key: 'Nivel_ries', values: ['Alto',"Medio", "Leve", "Muy Alto"], operation: 'OR' },
  //    { key: 'potencial_comercial_solar_norm', values: [">=0"], operation: 'OR' }
  // ]
  // )

  const default_use_colors = {
    Industrial          :  "#fc8d62",
    Agrícola            :  "#66c2a5",
    Retail              :  "#ffd92f",
    Oficinas            :  "#e5c494",
    'Servicios Públicos':  "#a6d854",
    Residencial         :  "#e78ac3",
    Otros               :  "#8da0cb",
  }
  const handleTitularidadChange = (event, newTitularidad) => {
    if (newTitularidad !== null) {
      setTitularidad(newTitularidad);
      console.log('Titularidad seleccionada:', newTitularidad);
    }
  };
  const getFilterValue = (filters, key)=>{
    return filters.filter((f)=>f.key==key)[0]
  }
  const getIsAsbestosFromFilters = (filters)=>{
    const f = getFilterValue(filters, "is_asbestos")
    console.log("f is_asbestos", f)
    if(!f || !f.values || f.values.length <=0){
      console.log("Devolviendo false por defecto")
      return false
    }
    console.log("Devolviendo valor", f.values, f.values.includes("false"))
    return f.values.includes("false")?false: true;
  }
  const getTitularidadFromFilters = (filters)=>{
    const f = getFilterValue(filters, "Titular")
    let isPrivado = false;
    let isPublico = false;
    if(!f){
      return "Todo"
    }
    console.log("Obteniendo valor  dee titularidad desde filtros", filters, f)
    if(f.values.includes("Privado")){
      isPrivado = true;
    }
    if(f.values.includes("Público")){
      isPublico = true;
    }
    if(isPublico && isPrivado){
      return "Todo"
    }else if(isPublico){
      return "Público"
    }else if(isPrivado){
      return "Privado"
    }else{
      return "Todo"
    }
  }
  const getUsesFromFilters = (filters)=>{
    const f = getFilterValue(filters, "Uso")
    console.log("Obteniendo usos de filtros",filters, f)
    let default_values = {
      Industrial: false,
      Agrícola: false,
      Retail: false,
      Oficinas: false,
      'Servicios Públicos': false,
      Residencial: false,
      Otros: false,
    }

    if(!f){
      return {
        Industrial: true,
        Agrícola: true,
        Retail: true,
        Oficinas: true,
        'Servicios Públicos': true,
        Residencial: true,
        Otros: true,
      }
    }
    for(const key of f.values){
      console.log("key", key)
      default_values[key] = true
    }
    return default_values
  }
  const getSusceptibilidadFromFilters = (filters)=>{
    const f = getFilterValue(filters, "Nivel_ries")
    console.log("Susceptibilidad filter value", f)
    if(!f){
      return 0
    }
    // if(f.values.includes("Muy Alto")){
    //   console.log("Susceptibilidad filter value - Muy alto")
    //   return 3
    // }else if (f.values.includes("Alto")){
    //   console.log("Susceptibilidad filter value - alto")
    //   return 2
    // }else if (f.values.includes("Medio")){
    //   console.log("Susceptibilidad filter value - Medio")
    //   return 1
    // }else if (f.values.includes("Leve")){
    //   console.log("Susceptibilidad filter value - Leve")
    //   return 0
    // }
    if (f.values.includes("Leve")){
      return 0
    }else if (f.values.includes("Medio")){
      return 1
    }else if (f.values.includes("Alto")){
      return 2
    }else if (f.values.includes("Muy Alto")){
      return 3
    }
  }
  const getCosteOportunidadFromFilters = (filters)=>{
    const f = getFilterValue(filters, "potencial_comercial_solar_norm")
    if(!f){
      return 0
    }
    return parseFloat(f.values[0].replace(">=", ""))
  }
  const [hasMounted, setHasMounted] = useState(false); // Estado para controlar si es el primer renderizado
  const [titularidad, setTitularidad] = useState(getTitularidadFromFilters(filters));
  const [isAsbestos, setIsAsbestos] = useState(getIsAsbestosFromFilters(filters));

  const [tipoUso, setTipoUso] = useState(getUsesFromFilters(filters));
  const [susceptibilidad, setSusceptibilidad] = useState(getSusceptibilidadFromFilters(filters));
  const [costeOportunidad, setCosteOportunidad] = useState(getCosteOportunidadFromFilters(filters));
  const [costeOportunidadView, setCosteOportunidadView] = useState(getCosteOportunidadFromFilters(filters));
  const lastExecuted = useRef(0); // Usamos `useRef` para almacenar el último tiempo de ejecución.
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  let timeOutCoste = null
  const handleTipoUsoChange = (event) => {
    setTipoUso({
      ...tipoUso,
      [event.target.name]: event.target.checked,
    });
    console.log('Tipo de uso seleccionado:', event.target.name, event.target.checked);
  };
  const handleIsAsbestosChange = (event)=>{
    setIsAsbestos(event.target.checked);
  }
  const handleSusceptibilidadChange = (event, newValue) => {
    setSusceptibilidad(newValue);
    console.log('Susceptibilidad de contener amianto:', newValue);
  };

  const handleCosteOportunidadChange = (event, newValue) => {

    if(timeOutCoste){
      clearTimeout(timeOutCoste)
    }
    setCosteOportunidadView(newValue);
    timeOutCoste = setTimeout(()=>{
      setCosteOportunidad(newValue);
      console.log('Coste / oportunidad solar:', newValue);
    }, 500)
    
  };
  const executeCallback = ()=>{
    const now = Date.now();
    // Actualizamos el último tiempo de ejecución
    lastExecuted.current = now;

    // Lógica para calcular los filtros
    console.log("usoValues", tipoUso);

    const usoValues = Object.keys(tipoUso).filter(key => tipoUso[key]);
    const titularValues = [];
    console.log("Calculando filtros", usoValues);

    if (titularidad === "Público") {
      titularValues.push("Municipal");
      titularValues.push("Público");
    } else if (titularidad === "Privado") {
      titularValues.push('Privado');
    } else {
      titularValues.push('Privado');
      titularValues.push("Municipal");
      titularValues.push("Público");
    }

    let riskValues = ["Leve", "Medio", 'Alto', "Muy Alto"];
    riskValues = riskValues.slice(susceptibilidad, riskValues.length);

    const costeOportunidadValue = ">=" + costeOportunidad.toFixed(2);
    
    let filter = [
      { key: 'Uso', values: usoValues, operation: 'OR' },
      { key: 'Titular', values: titularValues, operation: 'OR' },
      { key: 'Nivel_ries', values: riskValues, operation: 'OR' },
      { key: 'is_asbestos', values: isAsbestos?["=true"]:["=true", "=false"], operation: 'OR' },
      { key: 'potencial_comercial_solar_norm', values: [costeOportunidadValue], operation: 'OR' }

    ];

    filter = filter.filter(e => e.values.length > 0);
    console.log("filter", filter);

    callback(filter);
  }
  useEffect(() => {
    if (!hasMounted) {
      setHasMounted(true); // Marcar que ya se ha montado
      return;
    }
    const now = Date.now(); // Obtiene el timestamp actual en milisegundos.

    // Si ha pasado menos de 1 segundo desde la última ejecución, no se ejecuta el callback
    if (now - lastExecuted.current < 1000) {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
        
      }
      const newTimeout = setTimeout(() => {
        executeCallback()
      }, 1000)
      setDebounceTimeout(newTimeout);
      return;
    }
    executeCallback()

  }, [titularidad, tipoUso, susceptibilidad, costeOportunidad, isAsbestos, callback]);

  // const handleCallback = ()=>{
    
  // }
  const susceptibilidadMarks = [
    { value: 0, label: 'Leve' },
    { value: 1, label: 'Medio' },
    { value: 2, label: 'Alto' },
    { value: 3, label: 'Muy Alto' },
  ];

  return (
    <Paper sx={{ padding: '2rem', boxSizing: 'border-box', display:"flex", flexDirection:"column",textAlign:"left",  width: '100%' }}>
      {/* Filtro de Titularidad */}
      <div style={{display:"flex", flexDirection:"row", alignItems:"center"}}>
      <Typography variant="h6" style={{ height:"100%", paddingRight:"1rem", paddingBottom:"0.5rem"}}>Titularidad</Typography>
      <ToggleButtonGroup
        value={titularidad}
        exclusive
        onChange={handleTitularidadChange}
        aria-label="Filtro de Titularidad"
        sx={{ marginBottom: '1rem' }}
      >
        <ToggleButton value="Todo">Todo</ToggleButton>
        <ToggleButton value="Público">Público</ToggleButton>
        <ToggleButton value="Privado">Privado</ToggleButton>
      </ToggleButtonGroup>
      </div>
      
      <Divider style={{borderColor: "var(--color-primary-light-80)",margin:"1rem", marginBottom:"1.5rem"}} variant="middle"/>

      {/* Filtro de Tipo de Uso */}
      <Typography variant="h6">Tipo de Uso</Typography>
      <FormControl component="fieldset" sx={{ marginBottom: '1rem' }}>
        <FormGroup row>
          {Object.keys(tipoUso).map((tipo) => (
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    [`&, &.${checkboxClasses.checked}`]: {
                    color:Object.keys(default_use_colors).includes(tipo)? default_use_colors[tipo]:"red"
                  },
                  }}
                  iconStyle={{
                    fill:Object.keys(default_use_colors).includes(tipo)? default_use_colors[tipo]:"red"
                  }}
                  checked={tipoUso[tipo]}
                  onChange={handleTipoUsoChange}
                  name={tipo}
                />
              }
              label={tipo}
              key={tipo}
            />
          ))}
        </FormGroup>
      </FormControl>
      
      <Divider style={{borderColor: "var(--color-primary-light-80)",margin:"1rem", marginBottom:"1.5rem"}} variant="middle"/>

      {/* Susceptibilidad de Contener Amianto */}
      <div style={{display:"flex", flexDirection:"row", gap:"1rem"}}>
      <Typography variant="h6">Edificios con amianto</Typography>
      <Checkbox
        value={isAsbestos}
        checked={isAsbestos}
        onChange={handleIsAsbestosChange}
        valueLabelDisplay="auto"
        sx={{ marginBottom: '2rem' }}/>
        </div>
      <Typography variant="h6">Susceptibilidad de Contener Amianto</Typography>
      <CustomSlider
        value={susceptibilidad}
        min={0}
        max={3}
        step={1}
        marks={susceptibilidadMarks}
        onChange={handleSusceptibilidadChange}
        valueLabelDisplay="auto"
        sx={{ marginBottom: '2rem' }}
      />
      <Divider style={{borderColor: "var(--color-primary-light-80)",margin:"1rem", marginBottom:"1.5rem"}} variant="middle"/>
      
      {/* Coste / Oportunidad Solar */}
      <Typography variant="h6">Coste / Oportunidad Solar</Typography>
      <CustomSlider
        value={costeOportunidadView}
        min={0}
        max={1}
        step={0.01}
        onChange={handleCosteOportunidadChange}
        valueLabelDisplay="auto"
      />
    </Paper>
  );
};

export { FilterSolar };
