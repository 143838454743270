import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import { OSM } from 'ol/source';
import LayerGroup from 'ol/layer/Group';
import { getSourceBaseMapFromName } from './BaseMapSourcesService';

const get_pnoa_basemap = ()=>{
    return new TileLayer({
        id: "baseMap-PNOA", // ! NECESITA EMPEZAR POR baseMap
        source: new XYZ({
          url: 'https://tms-pnoa-ma.idee.es/1.0.0/pnoa-ma/{z}/{x}/{-y}.jpeg',
        }),
      });
}
// const get_osm_basemap = ()=>{
//     return new TileLayer({
//         id: "baseMap-OSM", // ! NECESITA EMPEZAR POR BASEMAP
//         source: new OSM(),
//       });
// }
const get_vector_layer_from_id = (id)=>{
    return ()=>{
        return getSourceBaseMapFromName(id)
       
    }
}

const get_layer_group_fn_from_id = (id)=>{
    return ()=>{
        let sourcesBaseMaps = getSourceBaseMapFromName(id)
        const layers = [];
        for (let source of sourcesBaseMaps) {
          layers.push(
            new TileLayer({
              source: source,
            })
          );
        }
        const lg =  new LayerGroup({
          layers: layers,
        });
        lg.set("id", "baseMap-group")
        return lg
    }
}

const get_basemaps_aviables = ()=>{
    return  {
        "PNOA":{
            map: get_layer_group_fn_from_id("PNOAWMTS"), // PNOAmaximaActualidad
            thumbnail:"map_satellite.jpg",
            title:"Vista aérea",
            description:"Este mapa base utiliza el WMTS proporcionado por el PNOA para una visualización limpia de fotografías de alta resolución del terreno",
            id:"PNOAWMTS",
        },
        "osm":{
            map: get_layer_group_fn_from_id("osm"),
            thumbnail:"map_street.jpg",
            title:"Callejero",
            description:"Callejero basado en Open Street Map donde se pueden visualizar nombres de calles y edificaciones de interés.",
            id:"osm",
        },
        "CATASTRO":{
            map: get_layer_group_fn_from_id("CATASTRO"),
            thumbnail:"map_catastro.jpeg",
            title:"Catastro",
            description:"Información relevante de las edificaciones obtenida directamente desde catastro",
            id:"CATASTRO",
        },
        "ZONAS CATASTRO":{
            map: get_layer_group_fn_from_id("ZONAS CATASTRO"),
            thumbnail:"map_catastro+pnoa.png",
            title:"Imágenes aéreas + Zonas de catastro",
            description:"Se muestran dos capas de información superpuestas. Por una parte, la información de las zonas edificadas procedente de catastro, por otra, imágenes aéreas de la zona.",
            id:"ZONAS CATASTRO",
        },
        // "google_hybrid":{
        //     map: get_layer_group_fn_from_id("google_hybrid"),
        //     thumbnail:"map_catastro+pnoa.png",
        //     title:"google_hybrid",
        //     description:"google_hybrid",
        //     id:"google_hybrid",
        // },
        // "esri_world_imagery":{
        //     map: get_layer_group_fn_from_id("esri_world_imagery"),
        //     thumbnail:"map_catastro+pnoa.png",
        //     title:"esri_world_imagery",
        //     description:"esri_world_imagery",
        //     id:"esri_world_imagery",
        // },
        "cyclosm":{
            map: get_layer_group_fn_from_id("cyclosm"),
            thumbnail:"map_topo.jpg",
            title:"Mapa topográfico",
            description:"Permite visualizar la altitud y relieve del terreno mediante curvas de nivel, así como elementos naturales (ríos, montañas, bosques) y artificiales (calles, carreteras, edificios).",
            id:"cyclosm",
        },
        // "heat_island":{
        //     map: get_layer_group_fn_from_id("heat_island"),
        //     thumbnail:"map_topo.jpg",
        //     title:"heat_island",
        //     description:"Mapa con información sobre la elevación del terreno además de referencias como los nombres de calles",
        //     id:"heat_island",
        // },
        // "water":{
        //     map: get_layer_group_fn_from_id("water"),
        //     thumbnail:"map_catastro+pnoa.png",
        //     title:"water",
        //     description:"water",
        //     id:"water",
        // },
        // "heat_all":{
        //     map: get_layer_group_fn_from_id("heat_all"),
        //     thumbnail:"map_catastro+pnoa.png",
        //     title:"heat_all",
        //     description:"heat_all",
        //     id:"heat_all",
        // },
        // "opentopomap":{
        //     map: get_layer_group_fn_from_id("opentopomap"),
        //     thumbnail:"map_catastro+pnoa.png",
        //     title:"opentopomap",
        //     description:"opentopomap",
        //     id:"opentopomap",
        // },
        "google_satellite":{
            map: get_layer_group_fn_from_id("google_satellite"),
            thumbnail:"map_satelite.jpg",
            title:"Imágenes de Google",
            description:"Procedente de servicios de Google. En ciertas regiones proporciona imágenes más actualizadas y con mayor resolución que las proporcionadas por IGN.",
            id:"google_satellite",
        },
        // "cartodb_darkmatter":{
        //     map: get_layer_group_fn_from_id("cartodb_darkmatter"),
        //     thumbnail:"map_catastro+pnoa.png",
        //     title:"cartodb_darkmatter",
        //     description:"cartodb_darkmatter",
        //     id:"cartodb_darkmatter",
        // },
        // "TopPlusOpenColor":{
        //     map: get_layer_group_fn_from_id("TopPlusOpenColor"),
        //     thumbnail:"map_catastro+pnoa.png",
        //     title:"TopPlusOpenColor",
        //     description:"TopPlusOpenColor",
        //     id:"TopPlusOpenColor",
        // },
        // "TopPlusOpenGrey":{
        //     map: get_layer_group_fn_from_id("TopPlusOpenGrey"),
        //     thumbnail:"map_catastro+pnoa.png",
        //     title:"TopPlusOpenGrey",
        //     description:"TopPlusOpenGrey",
        //     id:"TopPlusOpenGrey",
        // },
        // "StravaAll":{
        //     map: get_layer_group_fn_from_id("StravaAll"),
        //     thumbnail:"map_catastro+pnoa.png",
        //     title:"StravaAll",
        //     description:"StravaAll",
        //     id:"StravaAll",
        // },
        // "OpenStreetMapDE":{
        //     map: get_layer_group_fn_from_id("OpenStreetMapDE"),
        //     thumbnail:"map_catastro+pnoa.png",
        //     title:"OpenStreetMapDE",
        //     description:"OpenStreetMapDE",
        //     id:"OpenStreetMapDE",
        // },   
        // "USGSImagery":{
        //     map: get_layer_group_fn_from_id("USGSImagery"),
        //     thumbnail:"map_catastro+pnoa.png",
        //     title:"USGSImagery",
        //     description:"USGSImagery",
        //     id:"USGSImagery",
        // },
        // "USGSImageryTopo":{
        //     map: get_layer_group_fn_from_id("USGSImageryTopo"),
        //     thumbnail:"map_catastro+pnoa.png",
        //     title:"USGSImageryTopo",
        //     description:"USGSImageryTopo",
        //     id:"USGSImageryTopo",
        // },
        // "CATASTRO3D":{
        //     map: get_vector_layer_from_id("CATASTRO3D"),
        //     thumbnail:"map_catastro+pnoa.png",
        //     title:"Imágenes aéreas + Zonas de catastro",
        //     description:"Se muestran dos capas de información superpuestas. Por una parte, la información de las zonas edificadas procedente de catastro, por otra, imágenes aéreas de la zona.",
        //     id:"CATASTRO3D",
        // }
    } 
}

const get_default_base_map= (id=null)=>{
    const basemaps = get_basemaps_aviables()
    if(id == null || !Object.keys(basemaps).includes(id)){
        id = "PNOA"
    }
    return basemaps[id].map()
 
}

// const basemap_has_changed = (map, newBaseMapId)=>{
//     const currentBaseMap = find_current_basemap_layer(map);
//     const newBaseMap = 
//     if (currentBaseMap) {
//     }
// }

// Función para encontrar la capa base actual en el mapa
const find_current_basemap_layer = (map) => {
    const layers = map.getLayers().getArray();
    return layers.find(layer => layer.get('id') && layer.get('id').startsWith("baseMap"));
}

// Función para reemplazar la capa base
const replace_base_map = (map, newBaseMapId) => {
    console.log("Reemplazando mapa base por ", newBaseMapId, map)
    const currentBaseMap = find_current_basemap_layer(map);

    if (currentBaseMap) {
        map.removeLayer(currentBaseMap);  // Remover la capa base actual

    }
    if((!currentBaseMap) || (currentBaseMap && newBaseMapId !== currentBaseMap.get("id"))){
        const newBaseMap = get_default_base_map(newBaseMapId);  // Obtener la nueva capa base
        map.getLayers().insertAt(0, newBaseMap);  // Agregar la nueva capa base
    }

}

export {replace_base_map, find_current_basemap_layer, get_default_base_map, get_basemaps_aviables}