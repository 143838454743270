import { createRoot } from 'react-dom/client';
import Overlay from 'ol/Overlay';
import { fromLonLat } from 'ol/proj';

const addControlContainer = (map, mapContainerRef, component, position = 'bottom-right') => {
  // Crear un contenedor HTML donde se montará el componente React
  console.log("mapContainerRef", mapContainerRef.current, map)
  const controlContainer = document.createElement('div');
  controlContainer.className = "control-panel-container"; // Clase de estilo para hacer que luzca como un control de OpenLayers

  // Usamos createRoot de React 18 para montar el componente dentro del controlContainer
  const root = createRoot(controlContainer);
  root.render(component(map));

  // Crear el overlay de OpenLayers para añadir el contenedor al mapa
  const overlay = new Overlay({
    element: controlContainer,
    //stopEvent: false, // Permitir que el contenedor capture los eventos
  });

  // Posicionar el contenedor en la esquina adecuada
  map.addOverlay(overlay);
  const mapContainers = document.getElementsByClassName("map-container");
  if(mapContainers.length<0) return;
  mapContainers[0].appendChild(controlContainer); // Añadir al mapa
  //mapContainerRef.current.appendChild(controlContainer); // Añadir al mapa
  const verticalTop = "100px";
  const horizontalTop = "20px";
  const verticalBottom = "10px";
  const horizontalBottom = "20px";
  // Estilo CSS para posicionarlo en el mapa
  controlContainer.style.position = 'absolute';
  if (position === 'top-left') {
    controlContainer.style.top = verticalTop;
    controlContainer.style.left = horizontalTop;
  } else if (position === 'top-right') {
    controlContainer.style.top = verticalTop;
    controlContainer.style.right = horizontalTop;
  } else if (position === 'bottom-left') {
    controlContainer.style.bottom = verticalBottom;
    controlContainer.style.left = horizontalBottom;
  } else if (position === 'bottom-right') {
    controlContainer.style.bottom = verticalBottom;
    controlContainer.style.right = horizontalBottom;
  }
  //controlContainer.style.zIndex=0;
  controlContainer.style.width="fit-content";
  controlContainer.style.height="fit-content";
  

  return overlay;
};

export { addControlContainer };
