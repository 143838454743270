import React, { useEffect, useState, useContext } from "react";

import "./App.scss";
import { BrowserRouter, Route, Routes,Navigate,  Link } from "react-router-dom";
import Login from "./components/Screens/Login/Login";
import Main from "./components/Screens/Main/Main";
import WebFont from "webfontloader";
import authService from "./services/AuthService";
import '@geoapify/geocoder-autocomplete/styles/minimal.css';

function App() {
  
  React.useEffect(() => {
    WebFont.load({
      google: {
        families: ["IBM Plex Mono"],
      },
    });
   
  }, []);

  return (
    <div className="App">
      {/*<link rel="stylesheet" type="text/css" href="https://unpkg.com/@geoapify/geocoder-autocomplete@^1/styles/minimal.css"></link>*/}
      <BrowserRouter>
        <Routes>
            <>
              <Route path="/main" element={<PrivateRoute component={Main} redirectTo={"/login"}/>} />
              <Route path="/login" element={<Login />} index />
              <Route path="*" element={<Login />} />
            </>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

const PrivateRoute = ({ component: Component, redirectTo, ...rest }) => {
  const tokenExists = authService.getToken() !== null;

  if (!tokenExists) {
    return <Navigate to={redirectTo} />;
  }

  return <Component {...rest} />;
};


export default App;
