import React, { createContext, useEffect, useState, useRef } from "react";
import App from "../../App"
const AppContext = createContext({});

export function AppContextProvider() {
  const [baseStyle, setBaseStyle] = useState({ 
    languages: ["ES"], 
    baseColor1: "#000000" 
  })

  const [layersGroups, setLayersGroups] = useState([])
  const [clickViews, setClickViews] = useState([])
  const [dashboardsToShow, setDashboardsToShow] = useState([])
  const [legendUpdater, setLegendUpdater] = useState(0)
  const updateLegend = ()=>{
    setLegendUpdater(legendUpdater+1)
  }
  const layersGroupsRef = useRef(layersGroups);
  const mapElementRef = useRef(0);
  const mapInstanceRef = useRef(0);
  
  const [map, setMap] = useState(null);
  const [aoiExtent, setAoiExtent] = useState(null)
  const [ aoiUuid, setAoiUuid,] = useState(null);
  const [generalConfigParams, setGeneralConfigParams] = useState({
    basemap:"PNOA"
  })
  const [isWindowFeatureDetailsOpen, setIsWindowFeatureDetailsOpen] = useState(true);
  const [isWindowLayerDashboardOpen, setIsWindowLayerDashboardOpen] = useState(true);
  
  return (
    <AppContext.Provider
      value={{ 
        baseStyle, 
        generalConfigParams, setGeneralConfigParams, 
        layersGroups, setLayersGroups, 
        clickViews, setClickViews ,layersGroupsRef,
        map, setMap, mapElementRef,mapInstanceRef,
        isWindowLayerDashboardOpen, setIsWindowLayerDashboardOpen,
        dashboardsToShow, setDashboardsToShow,
        aoiUuid, setAoiUuid,updateLegend, legendUpdater,
        aoiExtent, setAoiExtent,
        isWindowFeatureDetailsOpen, setIsWindowFeatureDetailsOpen
      }}
    >
      <App />
    </AppContext.Provider>
  );
}

export default AppContext;
