import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS.js";
import { LayerStyleSelector } from "../../../LayerTools/LayerStyleSelector";
import Notiflix from "notiflix";
import { replaceLayerInGroupById } from "../../../../../../services/MapCore/LayerGroupService";
import { buildCQLFilter } from "../../../../../../services/MapCore/MapCQLFilterService";

const createSolarLayerWMS = (
  defaultLayer,
  onMapClick,
  map,
  handleChangeStyle,
  
  style   = "potencial_comercial_solar_norm_cuadrado",
  filters = [],
  
) => {
  const styles_aviables = [
    {
      label: "Coste/oportunidad potencial solar",
      id   : "potencial_comercial_solar_norm_cuadrado",
    },
    {
      label: "Tipo de uso del edificio",
      id   : "Uso",
    },
    {
      label: "Susceptibilidad de contener amianto",
      id   : "susceptibilidad",
    },
    {
      label:"Titularidad",
      id   :"Titular"
    },
    {
      label:"Edificios con amianto",
      id   :"Amianto"
    },
    {
      label: "Tiempo de amortización",
      id   : "amortization",
    },
  ];
  const default_style =style;// styles_aviables[0]["id"];

  console.log("Build CQL filters", buildCQLFilter(filters), filters)
  const susceptibilityBuildingLayer = Object.assign({}, defaultLayer);
  susceptibilityBuildingLayer.id    = "layer-poc_solar_amortization-building";
  function getChangeStyleComponent() {
    return (
      <LayerStyleSelector
        id={susceptibilityBuildingLayer.id}
        callback={(evt) => handleChangeStyle(evt, susceptibilityBuildingLayer.id)}
        styles_aviables={styles_aviables}
        default_style_id={style}
      />
    );
  }
  susceptibilityBuildingLayer.getChangeStyleComponent = getChangeStyleComponent;
  //susceptibilityBuildingLayer.opacity               = 1;
  susceptibilityBuildingLayer.styles_aviables         = styles_aviables;
  susceptibilityBuildingLayer.name                    ="Estudios de edificaciones";
  //susceptibilityBuildingLayer.zIndex                = 41;
  susceptibilityBuildingLayer.map                     = map;
  susceptibilityBuildingLayer.layer                   = new TileLayer({
    id: "poc_solar_amortization:fill_color",
    source: new TileWMS({
      url: "https://api.asbestos.ai/geoserver/agforest/wms",
      params: {
        LAYERS    : "agforest:poc_solar_amortization",
        FORMAT    : "image/png",
        STYLES    : default_style,
        TILED     : true,
        SRS       : "EPSG:4326", // Usar CRS para la versión 1.3.0 del WMS
        WIDTH     : 768,
        HEIGHT    : 600,
        VERSION   : "1.3.0",
        CQL_FILTER: buildCQLFilter(filters),
      },
     // cql_filter:"uuid = 'e1f83f7c-3fdb-4920-952e-eeac592157dc'",
      /*/*cql_filter:"USO = 'Industrial'",*/
      projection: "EPSG:4326",
      serverType: "geoserver",
      //   tileLoadFunction: function (tile, src) {
      //     const modifiedSrc = new LayerGroupSettings().reverseLonLatBBOXinURL(src);
      //     tile.getImage().src = modifiedSrc;
      //   },
    }),
  });

  susceptibilityBuildingLayer.onClickGetInfo = onMapClick;
  return susceptibilityBuildingLayer;
};

export { createSolarLayerWMS };
