import React, { useState, useEffect, useRef } from 'react';
import './BaseLayerSelector.css'; // Asegúrate de incluir los estilos
import { get_basemaps_aviables, replace_base_map } from '../../../../../services/MapCore/BaseMapsService'; 
import { z_index_values } from '../../../../../services/Commons/zIndexService';

const BaseLayerSelector = ({ map, direction = 'up',  icon }) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectorRef = useRef(null);
  const [baseMapsAviables, setBaseMapAviables]= useState({});

  useEffect(()=>{

    setBaseMapAviables(get_basemaps_aviables())
    
  }, [baseMapsAviables])
  

  // Detecta click externo para cerrar el desplegable
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectorRef.current && !selectorRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const handleBaseMapClick = (layer)=>{
    replace_base_map(map, layer.id)
  }
  // Maneja el hover o click para abrir/cerrar el desplegable
  const toggleDropdown = () => setIsOpen(!isOpen);

  return <div
      className={`layer-selector bg-dark layer-selector-${direction}`}
      ref={selectorRef}
      onClick={toggleDropdown}
      onMouseEnter={() => direction === 'hover' && setIsOpen(true)}
      onMouseLeave={() => direction === 'hover' && setIsOpen(false)}
    >
      <div className="layer-selector-trigger">
        {direction === 'up' ? 'Selector de capa base' : <img src={icon} alt="icon" />}
      </div>
      {isOpen && 
        <div style={{zIndex:z_index_values["control_map_basemap_selector"], overflowY:"auto !important"}}  className={`layer-selector-dropdown layer-selector-dropdown-${direction} `}> {/*layer-option-hover*/}
          {Object.keys(baseMapsAviables).map((key, index) => {
            const map = baseMapsAviables[key];
            return <div key={index} className="layer-option" onClick={(e)=>{e.preventDefault(); handleBaseMapClick(map)}}>
              <img src={"images/base_map/"+map.thumbnail} alt={map.title} className="layer-thumbnail" />
              <div className='layer-selector-dropdown-text-container'>
                <h4>{map.title}</h4>
                <p >{map.description}</p>
              </div>
            </div>
             })
          }
          </div>
      }
    </div>
};

export default BaseLayerSelector;
