import BingMaps from "ol/source/BingMaps.js";
import WMTS from "ol/source/WMTS.js";
import TileWMS from "ol/source/TileWMS.js";
import WMTSTileGrid from "ol/tilegrid/WMTS.js";
import XYZ from "ol/source/XYZ";
import OSM from "ol/source/OSM";
import { get as projget } from "ol/proj";
import GeoJSON from 'ol/format/GeoJSON.js';
import { Vector as VectorLayer } from "ol/layer";
import {
    getWidth,
    getTopLeft,
  } from "ol/extent";
import Style from "ol/style/Style";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import VectorSource from "ol/source/Vector";
import { bbox as bboxStrategy } from 'ol/loadingstrategy';
function createBuildingStyle(feature) {
  console.log("feature 3d", feature)
    const floors = feature.get('numberOfFloorsAboveGround') || 1; // Si no tiene valor, asumir 1 piso
    const height = floors * 3; // Aproximamos 3 metros por piso
  
    return new Style({
      fill: new Fill({
        color: 'rgba(100, 150, 240, 0.6)',
      }),
      stroke: new Stroke({
        color: '#3399CC',
        width: 1.5,
      }),
      zIndex: height, // Usamos la altura para la extrusión
    });
  }
  
export function getSourceBaseMapFromName(selectedValue) {
    let sources = [];
    // https://idee.es/servicios-teselas
    // https://land.copernicus.eu/en/map-viewer?dataset=4d0d78ad472c45819aff1d9fa7af0461
    
    if (selectedValue === "osm") {
      // Cambiar al mapa base OSM
      sources = [new OSM()];
    }   else if (selectedValue === "google_maps") {
      sources.push(
        new XYZ({
          url: "https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
        })
      );
    } 
    else if (selectedValue === "google_satellite") {
      sources.push(
        new XYZ({
          url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
        })
      );
    } 
    else if (selectedValue === "google_terrain") {
      sources.push(
        new XYZ({
          url: "https://mt1.google.com/vt/lyrs=p&x={x}&y={y}&z={z}",
        })
      );
    } 
    else if (selectedValue === "google_hybrid") {
      sources.push(
        new XYZ({
          url: "https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}",
        })
      );
    } 
    else if (selectedValue === "basemap_de_color") {
      sources.push(
        new XYZ({
          url: "https://sgx.geodatenzentrum.de/wmts_basemapde/tile/1.0.0/de_basemapde_web_raster_farbe/default/GLOBAL_WEBMERCATOR/{z}/{y}/{x}.png",
        })
      );
    } 
    else if (selectedValue === "basemap_de_grey") {
      sources.push(
        new XYZ({
          url: "https://sgx.geodatenzentrum.de/wmts_basemapde/tile/1.0.0/de_basemapde_web_raster_grau/default/GLOBAL_WEBMERCATOR/{z}/{y}/{x}.png",
        })
      );
    } 
    else if (selectedValue === "cartodb_darkmatter") {
      sources.push(
        new XYZ({
          url: "https://a.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png",
        })
      );
    } 
    else if (selectedValue === "cartodb_darkmatter_no_labels") {
      sources.push(
        new XYZ({
          url: "https://a.basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}.png",
        })
      );
    } 
    else if (selectedValue === "cartodb_positron") {
      sources.push(
        new XYZ({
          url: "https://a.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
        })
      );
    } 
    else if (selectedValue === "cyclosm") {
      sources.push(
        new XYZ({
          url: "https://a.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png",
        })
      );
    } 
    else if (selectedValue === "esri_world_imagery") {
      sources.push(
        new XYZ({
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
        })
      );
    } 
    else if (selectedValue === "opentopomap") {
      sources.push(
        new XYZ({
          url: "https://a.tile.opentopomap.org/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "OpenStreetMap") {
      sources.push(
        new XYZ({
          url: "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "GoogleMaps") {
      sources.push(
        new XYZ({
          url: "https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
        })
      );
    } else if (selectedValue === "GoogleSatellite") {
      sources.push(
        new XYZ({
          url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
        })
      );
    } else if (selectedValue === "GoogleTerrain") {
      sources.push(
        new XYZ({
          url: "https://mt1.google.com/vt/lyrs=p&x={x}&y={y}&z={z}",
        })
      );
    } else if (selectedValue === "GoogleHybrid") {
      sources.push(
        new XYZ({
          url: "https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}",
        })
      );
    } else if (selectedValue === "BaseMapDEColor") {
      sources.push(
        new XYZ({
          url: "https://sgx.geodatenzentrum.de/wmts_basemapde/tile/1.0.0/de_basemapde_web_raster_farbe/default/GLOBAL_WEBMERCATOR/{z}/{y}/{x}.png",
        })
      );
    } else if (selectedValue === "BaseMapDEGrey") {
      sources.push(
        new XYZ({
          url: "https://sgx.geodatenzentrum.de/wmts_basemapde/tile/1.0.0/de_basemapde_web_raster_grau/default/GLOBAL_WEBMERCATOR/{z}/{y}/{x}.png",
        })
      );
    } else if (selectedValue === "CartoDBDarkMatter") {
      sources.push(
        new XYZ({
          url: "https://a.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "CartoDBDarkMatterNoLabels") {
      sources.push(
        new XYZ({
          url: "https://a.basemaps.cartocdn.com/dark_nolabels/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "CartoDBDarkMatterOnlyLabels") {
      sources.push(
        new XYZ({
          url: "https://a.basemaps.cartocdn.com/dark_only_labels/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "CartoDBPositron") {
      sources.push(
        new XYZ({
          url: "https://a.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "CartoDBPositronNoLabels") {
      sources.push(
        new XYZ({
          url: "https://a.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "CartoDBPositronOnlyLabels") {
      sources.push(
        new XYZ({
          url: "https://a.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "CartoDBVoyager") {
      sources.push(
        new XYZ({
          url: "https://a.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "CartoDBVoyagerLabelsUnder") {
      sources.push(
        new XYZ({
          url: "https://a.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "CartoDBVoyagerNoLabels") {
      sources.push(
        new XYZ({
          url: "https://a.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "CartoDBVoyagerOnlyLabels") {
      sources.push(
        new XYZ({
          url: "https://a.basemaps.cartocdn.com/rastertiles/voyager_only_labels/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "CyclOSM") {
      sources.push(
        new XYZ({
          url: "https://a.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "EsriAntarcticBasemap") {
      sources.push(
        new XYZ({
          url: "https://tiles.arcgis.com/tiles/C8EMgrsFcRFL6LrL/arcgis/rest/services/Antarctic_Basemap/MapServer/tile/{z}/{y}/{x}",
        })
      );
    } else if (selectedValue === "EsriAntarcticImagery") {
      sources.push(
        new XYZ({
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/Polar/Antarctic_Imagery/MapServer/tile/{z}/{y}/{x}",
        })
      );
    } else if (selectedValue === "EsriArcticImagery") {
      sources.push(
        new XYZ({
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/Polar/Arctic_Imagery/MapServer/tile/{z}/{y}/{x}",
        })
      );
    } else if (selectedValue === "EsriArcticOceanBase") {
      sources.push(
        new XYZ({
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/Polar/Arctic_Ocean_Base/MapServer/tile/{z}/{y}/{x}",
        })
      );
    } else if (selectedValue === "EsriArcticOceanReference") {
      sources.push(
        new XYZ({
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/Polar/Arctic_Ocean_Reference/MapServer/tile/{z}/{y}/{x}",
        })
      );
    } else if (selectedValue === "EsriNatGeoWorldMap") {
      sources.push(
        new XYZ({
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}",
        })
      );
    } else if (selectedValue === "EsriOceanBasemap") {
      sources.push(
        new XYZ({
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/Ocean/World_Ocean_Base/MapServer/tile/{z}/{y}/{x}",
        })
      );
    } else if (selectedValue === "EsriWorldGrayCanvas") {
      sources.push(
        new XYZ({
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}",
        })
      );
    } else if (selectedValue === "EsriWorldImagery") {
      sources.push(
        new XYZ({
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
        })
      );
    } else if (selectedValue === "EsriWorldPhysical") {
      sources.push(
        new XYZ({
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Physical_Map/MapServer/tile/{z}/{y}/{x}",
        })
      );
    } else if (selectedValue === "EsriWorldShadedRelief") {
      sources.push(
        new XYZ({
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Shaded_Relief/MapServer/tile/{z}/{y}/{x}",
        })
      );
    } else if (selectedValue === "EsriWorldStreetMap") {
      sources.push(
        new XYZ({
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
        })
      );
    } else if (selectedValue === "EsriWorldTerrain") {
      sources.push(
        new XYZ({
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Terrain_Base/MapServer/tile/{z}/{y}/{x}",
        })
      );
    } else if (selectedValue === "EsriWorldTopoMap") {
      sources.push(
        new XYZ({
          url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
        })
      );
    } else if (selectedValue === "FreeMapSK") {
      sources.push(
        new XYZ({
          url: "https://a.freemap.sk/T/{z}/{x}/{y}.jpeg",
        })
      );
    } else if (selectedValue === "GaodeNormal") {
      // Nota: Gaode Maps puede tener restricciones de uso y requerir una clave API
      sources.push(
        new XYZ({
          url: "https://webrd0{s}.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=7&x={x}&y={y}&z={z}",
          maxZoom: 18,
          subdomains: ["1", "2", "3", "4"],
        })
      );
    } else if (selectedValue === "GaodeSatellite") {
      sources.push(
        new XYZ({
          url: "https://webst0{s}.is.autonavi.com/appmaptile?style=6&x={x}&y={y}&z={z}",
          maxZoom: 18,
          subdomains: ["1", "2", "3", "4"],
        })
      );
    } else if (selectedValue === "HikeBikeMap") {
      sources.push(
        new XYZ({
          url: "https://tiles.wmflabs.org/hikebike/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "HillShading") {
      sources.push(
        new XYZ({
          url: "https://tiles.wmflabs.org/hillshading/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "JusticeMapAmericanIndian") {
      sources.push(
        new XYZ({
          url: "https://www.justicemap.org/tile/county/indian/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "JusticeMapAsian") {
      sources.push(
        new XYZ({
          url: "https://www.justicemap.org/tile/county/asian/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "JusticeMapBlack") {
      sources.push(
        new XYZ({
          url: "https://www.justicemap.org/tile/county/black/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "JusticeMapHispanic") {
      sources.push(
        new XYZ({
          url: "https://www.justicemap.org/tile/county/hispanic/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "JusticeMapIncome") {
      sources.push(
        new XYZ({
          url: "https://www.justicemap.org/tile/county/income/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "JusticeMapMulti") {
      sources.push(
        new XYZ({
          url: "https://www.justicemap.org/tile/county/multi/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "JusticeMapNonWhite") {
      sources.push(
        new XYZ({
          url: "https://www.justicemap.org/tile/county/nonwhite/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "JusticeMapPlurality") {
      sources.push(
        new XYZ({
          url: "https://www.justicemap.org/tile/county/plural/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "JusticeMapWhite") {
      sources.push(
        new XYZ({
          url: "https://www.justicemap.org/tile/county/white/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "MtbMap") {
      sources.push(
        new XYZ({
          url: "http://tile.mtbmap.cz/mtbmap_tiles/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "OpenAIP") {
      // Nota: openAIP puede requerir registro o tener restricciones de uso
      sources.push(
        new XYZ({
          url: "https://{1-4}.tile.maps.openaip.net/geowebcache/service/tms/1.0.0/openaip_basemap@EPSG%3A900913@png/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "OpenFireMap") {
      sources.push(
        new XYZ({
          url: "http://openfiremap.org/hytiles/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "OpenRailwayMap") {
      sources.push(
        new XYZ({
          url: "https://a.tiles.openrailwaymap.org/standard/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "OpenSeaMap") {
      sources.push(
        new XYZ({
          url: "https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "OpenSnowMap") {
      sources.push(
        new XYZ({
          url: "https://tiles.opensnowmap.org/pistes/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "OpenStreetMapBZH") {
      sources.push(
        new XYZ({
          url: "https://tile.openstreetmap.bzh/br/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "OpenStreetMapBlackAndWhite") {
      sources.push(
        new XYZ({
          url: "https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "OpenStreetMapCH") {
      sources.push(
        new XYZ({
          url: "https://tile.osm.ch/switzerland/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "OpenStreetMapDE") {
      sources.push(
        new XYZ({
          url: "https://tile.openstreetmap.de/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "OpenStreetMapHOT") {
      sources.push(
        new XYZ({
          url: "https://a.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "OpenTopoMap") {
      sources.push(
        new XYZ({
          url: "https://a.tile.opentopomap.org/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "SafeCast") {
      sources.push(
        new XYZ({
          url: "https://s3.amazonaws.com/te512.safecast.org/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "StravaAll") {
      // Nota: Strava Heatmap requiere una clave API y autenticación
      sources.push(
        new XYZ({
          url: "https://heatmap-external-a.strava.com/tiles/all/hot/{z}/{x}/{y}.png?Key=YOUR_API_KEY",
          // Reemplaza YOUR_API_KEY con tu clave de Strava
        })
      );
    } else if (selectedValue === "SwissFederalGeoportalJourneyThroughTime") {
      sources.push(
        new XYZ({
          url: "https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.zeitreihen/default/18641231/3857/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "SwissFederalGeoportalNationalMapColor") {
      sources.push(
        new XYZ({
          url: "https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.pixelkarte-farbe/default/current/3857/{z}/{x}/{y}.jpeg",
        })
      );
    } else if (selectedValue === "SwissFederalGeoportalNationalMapGrey") {
      sources.push(
        new XYZ({
          url: "https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.pixelkarte-grau/default/current/3857/{z}/{x}/{y}.jpeg",
        })
      );
    } else if (selectedValue === "SwissFederalGeoportalSWISSIMAGE") {
      sources.push(
        new XYZ({
          url: "https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.swissimage/default/current/3857/{z}/{x}/{y}.jpeg",
        })
      );
    } else if (selectedValue === "TopPlusOpenColor") {
      sources.push(
        new XYZ({
          url: "http://sgx.geodatenzentrum.de/wmts_topplus_open/tile/1.0.0/web/default/WEBMERCATOR/{z}/{y}/{x}.png",
        })
      );
    } else if (selectedValue === "TopPlusOpenGrey") {
      sources.push(
        new XYZ({
          url: "http://sgx.geodatenzentrum.de/wmts_topplus_open/tile/1.0.0/web_grau/default/WEBMERCATOR/{z}/{y}/{x}.png",
        })
      );
    } else if (selectedValue === "USGSImagery") {
      sources.push(
        new XYZ({
          url: "https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryOnly/MapServer/tile/{z}/{y}/{x}",
        })
      );
    } else if (selectedValue === "USGSImageryTopo") {
      sources.push(
        new XYZ({
          url: "https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryTopo/MapServer/tile/{z}/{y}/{x}",
        })
      );
    } else if (selectedValue === "USGSTopo") {
      sources.push(
        new XYZ({
          url: "https://basemap.nationalmap.gov/arcgis/rest/services/USGSTopo/MapServer/tile/{z}/{y}/{x}",
        })
      );
    } else if (selectedValue === "WaymarkedTrailsCycling") {
      sources.push(
        new XYZ({
          url: "https://tile.waymarkedtrails.org/cycling/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "WaymarkedTrailsHiking") {
      sources.push(
        new XYZ({
          url: "https://tile.waymarkedtrails.org/hiking/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "WaymarkedTrailsMtb") {
      sources.push(
        new XYZ({
          url: "https://tile.waymarkedtrails.org/mtb/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "WaymarkedTrailsRiding") {
      sources.push(
        new XYZ({
          url: "https://tile.waymarkedtrails.org/riding/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "WaymarkedTrailsSkating") {
      sources.push(
        new XYZ({
          url: "https://tile.waymarkedtrails.org/skating/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "WaymarkedTrailsSlopes") {
      sources.push(
        new XYZ({
          url: "https://tile.waymarkedtrails.org/slopes/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "nlmapsGrijs") {
      sources.push(
        new XYZ({
          url: "https://service.pdok.nl/brt/achtergrondkaart/wmts/v2_0/grijs/EPSG:3857/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "nlmapsLuchtfoto") {
      sources.push(
        new XYZ({
          url: "https://service.pdok.nl/hwh/luchtfotorgb/wmts/v1_0/Actueel_ortho25/EPSG:3857/{z}/{x}/{y}.jpeg",
        })
      );
    } else if (selectedValue === "nlmapsPastel") {
      sources.push(
        new XYZ({
          url: "https://service.pdok.nl/brt/achtergrondkaart/wmts/v2_0/pastel/EPSG:3857/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "nlmapsStandaard") {
      sources.push(
        new XYZ({
          url: "https://service.pdok.nl/brt/achtergrondkaart/wmts/v2_0/standaard/EPSG:3857/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "nlmapsWater") {
      sources.push(
        new XYZ({
          url: "https://service.pdok.nl/brt/achtergrondkaart/wmts/v2_0/water/EPSG:3857/{z}/{x}/{y}.png",
        })
      );
    } else if(selectedValue === "heat_all"){
      sources.push(
        new XYZ({
          url: "https://heatmap-external-a.strava.com/tiles/all/hot/{z}/{x}/{y}.png",
        })
      );
      

    }else if(selectedValue === "water"){
      sources.push(
        new XYZ({
          url: "https://service.pdok.nl/brt/achtergrondkaart/wmts/v2_0/water/EPSG:3857/{z}/{x}/{y}.png",
        })
      );
      
    }else if (selectedValue === "voyayer") {
      // Cambiar a tu mapa base personalizado (ajusta según tus necesidades)
      sources.push(
        new XYZ({
          url: "http://basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png",
        })
      );
    } else if (selectedValue === "light_all") {
      // Cambiar a tu mapa base personalizado (ajusta según tus necesidades)
      sources.push(
        new XYZ({
          url: "http://{1-4}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png",
        })
      );
    } else if (selectedValue === "arcgis") {
      sources.push(
        new XYZ({
          url: "http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png",
        })
      );
    } else if (selectedValue === "PNOAmaximaActualidad") {
      sources.push(
        new XYZ({
          url: "https://tms-pnoa-ma.idee.es/1.0.0/pnoa-ma/{z}/{x}/{-y}.jpeg",
        })
      );
  
      //"https://www.ign.es/wms-inspire/pnoa-ma?request=GetCapabilities&service=WMS"
    }else if(selectedValue == "CATASTRO3D"){

      const source = new VectorSource({
        format: new GeoJSON(),
        url: (extent) =>
          `http://ovc.catastro.meh.es/INSPIRE/wfsBU.aspx?service=WFS&version=2.0.0&request=GetFeature&typename=cp:Building&outputFormat=application/json&srsname=EPSG:3857&bbox=${extent.join(',')},EPSG:3857`,
        strategy: bboxStrategy, // Para cargar los datos solo cuando el mapa los necesite
      })
      sources = new VectorLayer({
        source:source,
        style:createBuildingStyle
      })
      sources.set("id", "baseMap-group")
        
    }else if(selectedValue == "heat_island"){
      const source = new TileWMS({
        //url :'https://ovc.catastro.meh.es/Cartografia/WMS/PonenciasWMS.aspx',
        url: "https://tiles.maps.eox.at/wms",
        params: {
          //LAYERS: "MOD13Q1.2018145.006.NDVI", // Especifica las capas que deseas
          FORMAT: "image/png", // Formato de la imagen
          TILED: false,
          TRANSPARENT: "FALSE", // Transparencia de la capa
          SRS: "EPSG:4326", // Sistema de referencia espacial, asegúrate de que corresponda
          WIDTH: 256, // Ancho del tile en píxeles
          HEIGHT: 256, // Altura del tile en píxeles
        },
  
        projection: "EPSG:4326",
        serverType: "geoserver", // Ajusta según el tipo de servidor WMS
        crossOrigin: "anonymous",
      });
      sources = [source];
    } else if (selectedValue == "CATASTRO") {
      // https://github.com/anthonyjba/OpenLayer/blob/master/CATASTRO_3785.html
      const sourceCatastro = new TileWMS({
        //url :'https://ovc.catastro.meh.es/Cartografia/WMS/PonenciasWMS.aspx',
        url: "https://ovc.catastro.meh.es/Cartografia/WMS/ServidorWMS.aspx",
        params: {
          LAYERS: "Catastro", // Especifica las capas que deseas
          FORMAT: "image/png", // Formato de la imagen
          TILED: true,
          TRANSPARENT: "FALSE", // Transparencia de la capa
          SRS: "EPSG:3857", // Sistema de referencia espacial, asegúrate de que corresponda
          WIDTH: 256, // Ancho del tile en píxeles
          HEIGHT: 256, // Altura del tile en píxeles
        },
  
        projection: "EPSG:3857",
        serverType: "geoserver", // Ajusta según el tipo de servidor WMS
        crossOrigin: "anonymous",
      });
      const sourcePNOA = new XYZ({
        url: "https://tms-pnoa-ma.idee.es/1.0.0/pnoa-ma/{z}/{x}/{-y}.jpeg",
      });
      // source = source2
      sources = [sourceCatastro];
    } else if (selectedValue == "PNOAWMTS") {
      var projection = projget("EPSG:4326");
      var projectionExtent = projection.getExtent();
      var size = getWidth(projectionExtent) / 512;
      var resolutions = new Array(18);
      var matrixIds = new Array(18);
      for (var z = 0; z < 18; ++z) {
        // generate resolutions and matrixIds arrays for this WMTS
        resolutions[z] = size / Math.pow(2, z);
        matrixIds[z] = "EPSG:4326:" + z;
        //matrixIds[z] = z;
      }
      // var attribution = new Attribution({
      // 	html: 'Teselas de PNOA cedido por © Instituto Geográfico Nacional de España'
      // });
      sources.push(
        new WMTS({
          //  attributions: [attribution],
          url: "http://www.ign.es/wmts/pnoa-ma",
          layer: "OI.OrthoimageCoverage",
          matrixSet: "EPSG:4326",
          //matrixSet: 'EPSG:3857',
          format: "image/png",
          projection: projection,
          tileGrid: new WMTSTileGrid({
            origin: getTopLeft(projectionExtent),
            resolutions: resolutions,
            matrixIds: matrixIds,
          }),
          style: "default",
        })
      );
    }else if(selectedValue == "ZONAS CATASTRO"){
      const url = "http://ovc.catastro.meh.es/Cartografia/WMS/ponenciasWMS.aspx?SERVICE=WMS&SRS=EPSG:23030"
      //https://ovc.catastro.meh.es/Cartografia/WMS/ponenciasWMS.aspx?SERVICE=WMS&SRS=EPSG:23030&REQUEST=GETMAP&bbox=426500,4448300,430500,4451300&width=400&height=300&format=PNG&transparent=No&layers=zona%20valor
      const sourceCatastro = new TileWMS({
        //url :'https://ovc.catastro.meh.es/Cartografia/WMS/PonenciasWMS.aspx',
        url: "https://ovc.catastro.meh.es/Cartografia/WMS/PonenciasWMS.aspx",
        params: {
          LAYERS: "zona valor", // Especifica las capas que deseas
          FORMAT: "PNG", // Formato de la imagen
          TILED: true,
       //   TRANSPARENT: "No", // Transparencia de la capa
          SRS: "EPSG:3857", // Sistema de referencia espacial, asegúrate de que corresponda
          WIDTH: 256, // Ancho del tile en píxeles
          HEIGHT: 256, // Altura del tile en píxeles
        },
  
        projection: "EPSG:3857",
        serverType: "geoserver", // Ajusta según el tipo de servidor WMS
        crossOrigin: "anonymous",
      });
      var projection = projget("EPSG:4326");
      var projectionExtent = projection.getExtent();
      var size = getWidth(projectionExtent) / 512;
      var resolutions = new Array(18);
      var matrixIds = new Array(18);
      for (var z = 0; z < 18; ++z) {
        // generate resolutions and matrixIds arrays for this WMTS
        resolutions[z] = size / Math.pow(2, z);
        matrixIds[z] = "EPSG:4326:" + z;
        //matrixIds[z] = z;
      }
      // var attribution = new Attribution({
      // 	html: 'Teselas de PNOA cedido por © Instituto Geográfico Nacional de España'
      // });
      const layerPNOA =    new WMTS({
          //  attributions: [attribution],
          url: "http://www.ign.es/wmts/pnoa-ma",
          layer: "OI.OrthoimageCoverage",
          matrixSet: "EPSG:4326",

          //matrixSet: 'EPSG:3857',
          format: "image/png",
          projection: projection,
          tileGrid: new WMTSTileGrid({
            origin: getTopLeft(projectionExtent),
            resolutions: resolutions,
            matrixIds: matrixIds,
          }),
          style: "default",
        });

      sources = [ layerPNOA, sourceCatastro];
    } else if (selectedValue == "CATASTRO+PNOA") {
      // https://github.com/anthonyjba/OpenLayer/blob/master/CATASTRO_3785.html
      const sourceCatastro = new TileWMS({
        //url :'https://ovc.catastro.meh.es/Cartografia/WMS/PonenciasWMS.aspx',
        url: "https://ovc.catastro.meh.es/Cartografia/WMS/ServidorWMS.aspx",
        params: {
          LAYERS: "Catastro", // Especifica las capas que deseas
          FORMAT: "image/png", // Formato de la imagen
          TILED: true,
          TRANSPARENT: "TRUE", // Transparencia de la capa
          SRS: "EPSG:3857", // Sistema de referencia espacial, asegúrate de que corresponda
          WIDTH: 256, // Ancho del tile en píxeles
          HEIGHT: 256, // Altura del tile en píxeles
        },
  
        projection: "EPSG:3857",
        serverType: "geoserver", // Ajusta según el tipo de servidor WMS
        crossOrigin: "anonymous",
      });
      const sourcePNOA = new XYZ({
        url: "https://tms-pnoa-ma.idee.es/1.0.0/pnoa-ma/{z}/{x}/{-y}.jpeg",
      });
      // source = source2
      
      var projection = projget("EPSG:4326");
      var projectionExtent = projection.getExtent();
      var size = getWidth(projectionExtent) / 512;
      var resolutions = new Array(18);
      var matrixIds = new Array(18);
      for (var z = 0; z < 18; ++z) {
        // generate resolutions and matrixIds arrays for this WMTS
        resolutions[z] = size / Math.pow(2, z);
        matrixIds[z] = "EPSG:4326:" + z;
        //matrixIds[z] = z;
      }
      // var attribution = new Attribution({
      // 	html: 'Teselas de PNOA cedido por © Instituto Geográfico Nacional de España'
      // });
      const layerPNOA =    new WMTS({
          //  attributions: [attribution],
          url: "http://www.ign.es/wmts/pnoa-ma",
          layer: "OI.OrthoimageCoverage",
          matrixSet: "EPSG:4326",

          //matrixSet: 'EPSG:3857',
          format: "image/png",
          projection: projection,
          tileGrid: new WMTSTileGrid({
            origin: getTopLeft(projectionExtent),
            resolutions: resolutions,
            matrixIds: matrixIds,
          }),
          style: "default",
        });

        sources = [layerPNOA, sourceCatastro];
    } else if (selectedValue == "PNOARaster") {
      sources.push(
        new XYZ({
          url: "https://tms-mapa-raster.ign.es/1.0.0/mapa-raster/{z}/{x}/{-y}.jpeg",
        })
      );
    } else if (selectedValue == "PNOABaseTodo") {
      sources.push(
        new XYZ({
          url: "https://tms-ign-base.idee.es/1.0.0/IGNBaseTodo/{z}/{x}/{-y}.jpeg",
        })
      );
    } else if (selectedValue == "bing_AerialWithLabelsOnDemand") {
      //https://openlayers.org/en/latest/examples/bing-maps.html
      sources.push(
        new BingMaps({
          key: "Your Bing Maps Key from https://www.bingmapsportal.com/ here",
          imagerySet: "AerialWithLabelsOnDemand",
          //crossOrigin:"https://www.asbestos.ai",
          projection: "EPSG:4326",
          key: "ArEHaClBNxd5sNIvqtthamdnShR1GQkYSOxPMgNiQmn2d4fCXxWQ0Hdb2l8mUzI3", //"Ag97niDOM4O5tEEHeg9Myjw2L5UYWD1kS1ptgFdn7wJ2C5YFL5S7W5dosgx51LFU"
          placeholderTiles: false, // Optional. Prevents showing of BingMaps placeholder tiles
        })
      );
    } else if (selectedValue == "bing_OrdnanceSurvey") {
      //https://openlayers.org/en/latest/examples/bing-maps.html
      sources.push(
        new BingMaps({
          key: "Your Bing Maps Key from https://www.bingmapsportal.com/ here",
          imagerySet: "OrdnanceSurvey",
          //crossOrigin:"https://www.asbestos.ai",
          projection: "EPSG:4326",
          key: "ArEHaClBNxd5sNIvqtthamdnShR1GQkYSOxPMgNiQmn2d4fCXxWQ0Hdb2l8mUzI3", //"Ag97niDOM4O5tEEHeg9Myjw2L5UYWD1kS1ptgFdn7wJ2C5YFL5S7W5dosgx51LFU"
          placeholderTiles: false, // Optional. Prevents showing of BingMaps placeholder tiles
        })
      );
    }
    return sources;
  }