import { FeatureTable } from "../../../../../services/Commons/Feature/FeatureTable";
import {FeatureTableAccordion} from "../../../../../services/Commons/Feature/FeatureTableAccordion";
import { legendWMS } from "../../../Legends/WMSLegend";
import LayerGroupSettings from "../LayerGroupSettings";


import { createAOILayerWMS } from "./Layers/AOIWMS";

class LayerGroupAOI extends LayerGroupSettings {
  constructor(map=null, type="municipio") {
    super();
    this.id      = "group-aoi";
    this.name    = "Área de estudio";
    let title    = "Límites del área de estudio";
    let subtitle = "Límites del área de estudio";
    if(type == "municipio"){
      title    = "Límites administrativos";
      subtitle = "Municipio";
    }
    this.legends = [new legendWMS("https://api.asbestos.ai/geoserver/agforest/wms","agforest:layer_aoi_limits",title, "Low Zoom", subtitle)]
    this.map = map
    this.selectedFeatures = []
    this.layer_01 = createAOILayerWMS(this.defaultLayer, this.onMapClick, this.map);
   
    this.layers.push(
      this.layer_01,
    );
  }


  async onMapClick(evt, map) {
    return <></>;
  //   const featuresWMS =  await this.getFeatureInfoOnClickWMS(evt, map, this.layer_01.layer, "agforest:layer_aoi_limits")
  //   if(featuresWMS.length <=0){
  //     return false
  //   }
  //  const replacements = {
  //   "name":"Nombre del área de estudio",
  //   "fx_creation":"Fecha de creación"
  //  }
   
  //   return (
  //     <>
  //       {featuresWMS.map((f) => {
  //         return FeatureTableAccordion(f, "Datos generales del área de estudio", replacements);
  //       })}
  //       {/* {featuresWMS.map((f) => {
  //         return <label>{JSON.stringify(f)}</label>;
  //       })} */}
  //     </>
  //   );
  }
}
export default LayerGroupAOI;
