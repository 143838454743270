// LayerService.js

// Método para encontrar una capa o grupo de capas por ID
function findLayerById(map, layerId) {
  let targetLayer = null;

  const searchLayer = (layer) => {
    if (layer.get("id") === layerId) {
      targetLayer = layer;
    } else if (layer.getLayers) {
      // Si es un LayerGroup, buscar recursivamente
      layer.getLayers().forEach(searchLayer);
    }
  };

  map.getLayers().forEach(searchLayer);
  return targetLayer;
}

// Método para añadir una capa a un grupo de capas
function addLayerToGroup(map, groupId, newLayer) {
  const layerGroup = findLayerById(map, groupId);

  if (layerGroup && layerGroup.getLayers) {
    // Añadir la nueva capa al grupo de capas
    layerGroup.getLayers().push(newLayer);
    console.log(`Capa con ID ${newLayer.get("id")} añadida al grupo con ID ${groupId}.`);
  } else {
    console.warn(`Grupo de capas con ID ${groupId} no encontrado o no es un LayerGroup.`);
  }
}

// Método para reemplazar una capa dentro de un grupo de capas por su ID
function replaceLayerInGroupById(map, groupId, oldLayerId, newLayer) {
  const layerGroup = findLayerById(map, groupId);

  if (layerGroup && layerGroup.getLayers) {
    const layersCollection = layerGroup.getLayers();
    let oldLayer = null;
    console.log("Layers", layersCollection)
    // Encontrar y eliminar la capa antigua
    layersCollection.forEach((layer, index) => {
      console.log("Layer", layer)
      if (layer && layer.get("id") === oldLayerId) {
        oldLayer = layer;
        layersCollection.removeAt(index);
        console.log(`Capa con ID ${oldLayerId} eliminada del grupo con ID ${groupId}.`);
      }
    });

    // Añadir la nueva capa si la antigua fue encontrada
    if (oldLayer) {
      addLayerToGroup(map, groupId, newLayer);
    } else {
      console.warn(`Capa con ID ${oldLayerId} no encontrada en el grupo con ID ${groupId}. No se ha reemplazado.`);
    }
  } else {
    console.warn(`Grupo de capas con ID ${groupId} no encontrado o no es un LayerGroup.`);
  }
}

// Método para eliminar una capa por ID
function removeLayerById(map, layerId) {
  const layer = findLayerById(map, layerId);
  if (layer) {
    map.removeLayer(layer);
    console.log(`Capa con ID ${layerId} eliminada.`);
  } else {
    console.warn(`Capa con ID ${layerId} no encontrada.`);
  }
}

// Método para añadir una capa nueva al mapa
function addLayer(map, newLayer) {
  map.addLayer(newLayer);
  console.log(`Capa con ID ${newLayer.get("id")} añadida.`);
}

// Método para sustituir una capa por otra utilizando el ID
function replaceLayerById(map, layerId, newLayer) {
  const layer = findLayerById(map, layerId);
  if (layer) {
    map.removeLayer(layer);
    map.addLayer(newLayer);
    console.log(`Capa con ID ${layerId} sustituida.`);
  } else {
    console.warn(`Capa con ID ${layerId} no encontrada para sustituir.`);
  }
}

// Método para establecer la visibilidad de una capa
function setLayerVisibility(map, layerId, visibility) {
  const layer = findLayerById(map, layerId);
  if (layer) {
    layer.setVisible(visibility);
  } else {
    console.warn(`Capa con ID ${layerId} no encontrada.`);
  }
}

// Método para establecer la opacidad de una capa
function setLayerOpacity(map, layerId, opacity) {
  const layer = findLayerById(map, layerId);
  if (layer) {
    layer.setOpacity(opacity);
  } else {
    console.warn(`Capa con ID ${layerId} no encontrada.`);
  }
}

// Método para establecer el z-index de una capa
function setLayerZIndex(map, layerId, zIndex) {
  const layer = findLayerById(map, layerId);
  if (layer) {
    layer.setZIndex(zIndex);
  } else {
    console.warn(`Capa con ID ${layerId} no encontrada.`);
  }
}

export { findLayerById, addLayerToGroup, replaceLayerInGroupById, removeLayerById, addLayer, replaceLayerById, setLayerVisibility, setLayerOpacity, setLayerZIndex };
