import React, { useRef, useEffect } from 'react';
import Map from 'ol/Map';
import View from 'ol/View';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { OSM } from 'ol/source';
import { Vector as VectorSource } from 'ol/source';
import { Style, Stroke, Fill, Circle as CircleStyle } from 'ol/style';
import Feature from 'ol/Feature';
import { Polygon } from 'ol/geom';
import { get_default_base_map } from '../../../services/MapCore/BaseMapsService';

const MiniMap = ({ props, style, name="Edificación" }) => {
    const feature = new Feature({
        geometry:props["geometry"],
        //labelPoint: new Point(labelCoords),
        name: name,
    });
  const mapRef = useRef(null);
  const mapInstanceRef = useRef(null);

  useEffect(() => {
    // Inicializa el mapa solo una vez
    
    if (!mapInstanceRef.current) {
      mapInstanceRef.current = new Map({
        target: mapRef.current,
        layers: [
          // new TileLayer({
          //   source: new OSM(),
          // }),
          get_default_base_map()
        ],
        view: new View({
          center: [0, 0],
          zoom: 2,
          projection: "EPSG:4326",
        }),
        controls: [], // Remueve los controles predeterminados si lo deseas
      });
    }

    // Limpia al desmontar el componente
    return () => {
      if (mapInstanceRef.current) {
        mapInstanceRef.current.setTarget(null);
        mapInstanceRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    const map = mapInstanceRef.current;
    if (!map || !feature || !feature.getGeometry()) return;

    // Remueve las capas vectoriales existentes
    map.getLayers().forEach(layer => {
      if (layer instanceof VectorLayer) {
        map.removeLayer(layer);
      }
    });

    // Crea una fuente vectorial y agrega la feature
    const vectorSource = new VectorSource({
      features: [feature],
    });

    // Crea una capa vectorial con estilo
    const vectorLayer = new VectorLayer({
      source: vectorSource,
      style: new Style({
        stroke: new Stroke({
          color: 'blue',
          width: 2,
        }),
        fill: new Fill({
          color: 'rgba(0, 0, 255, 0.1)',
        }),
        image: new CircleStyle({
          radius: 5,
          fill: new Fill({ color: 'blue' }),
        }),
      }),
    });

    // Agrega la capa vectorial al mapa
    map.addLayer(vectorLayer);

    // Actualiza el tamaño del mapa (por si aún no se ha renderizado)
    map.updateSize();

    // Ajusta la vista a la extensión de la feature
    const extent = feature.getGeometry().getExtent();
    console.log("extent", extent)
    map.getView().fit(extent, {
      duration:100,
      padding: [5, 5, 5, 5],
      maxZoom: 19, // Ajusta según sea necesario
    });

  }, [feature, mapInstanceRef]);

  return <div ref={mapRef} style={{ width: '100%', height: '100%', ...style }} />;
};

export {MiniMap};
