import React, { useState, useEffect, useRef, useContext } from 'react';
import './MapComponent.css';
import AppContext from '../Contexts/AppContext';
import {
  add_mouse_click_event,
  add_visualization_layers_to_map,
  createMarker,
  createMarkerLayer,
  get_inicial_map,
} from '../../services/MapCore/MapService';
import { center_map_from_extent } from '../../services/MapCore/AOIService';
import { addControlContainer } from './Controls/ControlsConfiguration';
import { ControlPanel } from './Controls/Controls';
import { addMarkerToMap } from '../../services/MapCore/MarkerService';

function MapWrapper({}) {
  const {
    generalConfigParams,
    setGeneralConfigParams,
    layersGroups,
    setLayersGroups,
    cllikViews,
    setClickViews,
    map,
    setMap,
    mapElementRef,
    mapInstanceRef,
    isWindowFeatureDetailsOpen,
    setIsWindowFeatureDetailsOpen,
    aoiExtent,
    setAoiExtent,layersGroupsRef,
  } = useContext(AppContext);

  

  useEffect(() => {
    layersGroupsRef.current = layersGroups;
  }, [layersGroups]);

  const map_init = () => {
    const initialMap = get_inicial_map(mapElementRef);
    setMap(initialMap);
    mapInstanceRef.current = initialMap;
    add_mouse_click_event(initialMap, map_callback);
    addControlContainer(initialMap,mapElementRef,  ControlPanel)
    return initialMap;
  };

  const map_callback = async function (evt) {
    console.log('Iniciando evento map click', layersGroupsRef.current);
    addMarkerToMap(mapInstanceRef.current, evt.coordinate)
    // let markerLayer = mapInstanceRef.current
    //   .getLayers()
    //   .getArray()
    //   .find(layer => layer.get('name') === 'markerLayer');
    // if (!markerLayer) {
    //   markerLayer = createMarkerLayer();
    //   markerLayer.set('name', 'markerLayer');
    //   mapInstanceRef.current.addLayer(markerLayer);
    // }
    // evt.stopPropagation();
    // markerLayer.getSource().clear();

    // // Agregar un nuevo marcador
    // const coordinate = evt.coordinate;
    // const marker = createMarker(coordinate);
    // markerLayer.getSource().addFeature(marker);
    const views = [];
    for(const viz of layersGroupsRef.current){
      const view = await viz.onMapClick(evt, mapInstanceRef.current)
        if (view) {
          views.push({
            name: viz.name,
            view: view,
          });
        }
    };
    // await layersGroupsRef.current.map(viz => {
    //   viz.onMapClick(evt, mapInstanceRef.current).then(view => {
    //     if (view) {
    //       views.push({
    //         name: viz.name,
    //         view: view,
    //       });
    //     }
    //   });
    // });

    // console.log(
    //   'LayersGroups añadidas, ',
    //   layersGroupsRef.current,
    //   mapInstanceRef.current,
    //   views
    // );
    setClickViews(views);
    if (views.length > 0) {
      setIsWindowFeatureDetailsOpen(true);
    } else {
      setIsWindowFeatureDetailsOpen(false);
    }
  };

  useEffect(() => {
    // Crear el mapa base
   // console.log('Iniciando mapa', map, mapElementRef);
    let initialMap = null;
    if (mapElementRef && !mapInstanceRef.current && map == null) {
      initialMap = map_init();
     
    } else {
      console.error('MapRef no existe??', map, mapElementRef);
    }
    return () => {
      if (initialMap) {
        initialMap.setTarget(null); // Limpiar el mapa al desmontar
        if (map) {
          setMap(null);
        }
      }
      if(mapElementRef.current){
        mapElementRef.current.setTarget(null);
        mapElementRef.current = null;
      }
      if (mapInstanceRef.current) {
        mapInstanceRef.current.setTarget(null);
        mapInstanceRef.current = null;
      }
    };
  }, [mapElementRef, mapInstanceRef]);

  useEffect(() => {
    console.log('Renderizando capas...', layersGroups);
    if (mapInstanceRef.current) {
      addControlContainer(mapInstanceRef.current, mapElementRef, ControlPanel)

      const baseLayer = mapInstanceRef.current.getLayers().item(0);
      mapInstanceRef.current.getLayers().clear();
      mapInstanceRef.current.getLayers().push(baseLayer);

      add_visualization_layers_to_map(mapInstanceRef.current, layersGroups);
    } else {
      console.log(
        'ERROR - Mapa no existente, error renderizando campas.',
        mapInstanceRef
      );
    }
  }, [layersGroups]);

  return <div ref={mapElementRef} className="map-container"></div>;
}

export default MapWrapper;
