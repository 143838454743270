class LayerGroupSettings {
  constructor(map) {
    this.id            = "group-";
    this.name          = "";
    this.layers        = [];
    this.legends       = []
    this.visible       = true
    this.map           = map;
    this.getFilter     = ()=><></>;
    this.has_filter    = false;
    this.has_dashboard = false;
    this.defaultLayer  = {
      layer                        : null,
      id                      : "layer-",
      name                    : "",
      map                     : false,
      aviable_styles          : [],
      getChangeStyleComponent : (new_style)=>{},
      styles_aviables         :[], 
      filter_aviables         :[],
      opacity                 : 1,
      zIndex                  : 50,
      visible                 : true,
      onClickGetInfo          : null,
      controlSettingsComponent:false,
    };
  }

  // Método para agregar una layer
  agregarLayer(id, layer, style, opacity = 1, zIndex = 1, visible = true) {
    this.layers.push({ id, layer, style, zIndex, opacity, visible });
  }

  // Método para eliminar una layer
  eliminarLayer(layer) {
    this.layers = this.layers.filter((c) => c.layer !== layer);
  }

  setLayerVisibility(id, visible) {
    const layer = this.layers.find((layer) => layer.id === id);
    if (layer) {
      layer.visible = visible;
    }
  }

  setLayerOpacity(id, opacity) {
    const layer = this.layers.find((layer) => layer.id === id);
    if (layer) {
      layer.opacity = opacity;
    }
  }

  setLayerZIndex(id, zIndex) {
    const layer = this.layers.find((layer) => layer.id === id);
    if (layer) {
      layer.zIndex = zIndex;
    }
  }


  async getFeatureInfoOnClickWMS(evt, map, layer, geoserver_layer_name) {
    //evt.stopPropagation();
    const wmsSource = layer.getSource();
    const clickedCoordinate = map.getEventCoordinate(evt);
    const [lon, lat] = clickedCoordinate;
    const bboxSize = 0.01; // Esto definirá un pequeño área alrededor del clic (ajústalo según tus necesidades)
    const bboxMinX = lon - bboxSize / 2;
    const bboxMinY = lat - bboxSize / 2;
    const bboxMaxX = lon + bboxSize / 2;
    const bboxMaxY = lat + bboxSize / 2;

    const bbox = [bboxMinX, bboxMinY, bboxMaxX, bboxMaxY].join(",");
    const url = wmsSource.getFeatureInfoUrl(
      evt.coordinate,//clickedCoordinate,
      map.getView().getResolution(),
      "EPSG:4326", // Usamos EPSG:4326, o ajusta según la proyección que uses
      {
        INFO_FORMAT  : "application/json",
        QUERY_LAYERS : geoserver_layer_name, //'agforest:poc_layer_catastro',
        FEATURE_COUNT: 10,
     //   WIDTH: 101, // Ancho fijo para la imagen de consulta
      //  HEIGHT: 101, // Alto fijo para la imagen de consulta
       // I: 50, // Posición X fija
      //  J: 50, // Posición Y fija
      //  BBOX: bbox, // El BBOX centrado alrededor del clic
      }
    );

    // Realizar la solicitud GetFeatureInfo
    const r = await fetch(url, { 
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        Accept: "*/*",
      },
      //mode: 'no-cors'
    })
      .then((response) => response.json())
      .then((data) => {
        console.debug("Datos obtenidos de la feature:", data);
        if (data.features && data.features.length > 0) {
          //console.log('Feature info: ' + JSON.stringify(featureInfo));
          return data.features;
        } else {
          // alert('No hay features en el área seleccionada.');
          console.log(
            "No hay features de " +
              geoserver_layer_name +
              " en las coordenadas seleccionadas"
          );
          return [];
        }
      })
      .catch((error) => {
        console.error("Error al obtener la información de la feature:", error);
        return [];
      });
    return r;
  }
  async getFeaturesInfoOnClickMVT(evt, map, this_layer){
    //evt.stopPropagation();
    const pixel = map.getEventPixel(evt.originalEvent);
    //var pointClicked = map.getCoordinateFromPixel(pixel);
    const features = []
    let last_feature = map.forEachFeatureAtPixel(pixel, function (feature, layer) {
      if (layer && layer.get('id') === this_layer.get('id')) {
        features.push(feature)
      }
      return feature;
    });
    return features
  }
  reverseLonLatBBOXinURL(url) {
    // Decodificar la URL por si tiene caracteres codificados como %2C (que es una coma)
    const decodedUrl = decodeURIComponent(url);
    console.log("URL decodificada:", decodedUrl);

    // Buscar el parámetro BBOX en la URL
    const bboxIndex = decodedUrl.indexOf("BBOX=");
    if (bboxIndex === -1) {
      return url; // Si no se encuentra BBOX, devolver la URL tal como está
    }

    // Extraer la subcadena del BBOX
    const bboxString = decodedUrl.substring(bboxIndex + 5); // +5 para saltar el "BBOX="

    // Encontrar el siguiente "&" después del BBOX para saber dónde termina
    const bboxEndIndex = bboxString.indexOf("&");
    const bbox =
      bboxEndIndex === -1 ? bboxString : bboxString.substring(0, bboxEndIndex);

    console.log("BBOX encontrado:", bbox);

    // Separar los valores del BBOX en un array
    const bboxValues = bbox.split(",").map(parseFloat);
    if (bboxValues.length !== 4) {
      console.log("BBOX tiene un formato incorrecto.");
      return url; // Si no tiene exactamente 4 valores, algo está mal
    }

    console.log("Valores de BBOX antes de invertir:", bboxValues);

    // Invertir latitud y longitud (cambiamos el orden de los ejes)
    const newBboxValues = [
      bboxValues[1],
      bboxValues[0],
      bboxValues[3],
      bboxValues[2],
    ];
    console.log("Valores de BBOX después de invertir:", newBboxValues);

    // Crear el nuevo BBOX como string
    const newBboxString = newBboxValues.join(",");

    // Reemplazar el viejo BBOX con el nuevo BBOX en la URL
    const modifiedUrl = decodedUrl.replace(bbox, newBboxString);

    console.log("URL modificada:", modifiedUrl);

    return modifiedUrl;
  }
}
export default LayerGroupSettings;
