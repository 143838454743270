import React, { useEffect, useState, useRef } from "react";
import { Rnd } from "react-rnd";
import "./FloatingWindow.css";
import { z_index_values } from "../../../services/Commons/zIndexService";
import { Paper, Tooltip, LinearProgress, IconButton } from "@mui/material"; // Añadimos LinearProgress para la barra
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
const FloatingWindow = ({
  title,
  content,
  onClose,
  initial_position_x = 100,
  initial_position_y = 100,
  initial_width = 300,
  initial_height = 350,
  enable_drag = true,
  enable_resize = true,
  enable_minimize = true,
  minimize_callback = false,
  zIndex = z_index_values["default_floating_window"],
  start_collapsed = false,
  max_height = 600, // Parámetro para la altura máxima
  allow_maximize=false,
}) => {
  const [isMinimized, setIsMinimized] = useState(start_collapsed);
  const [isDragging, setIsDragging] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [progress, setProgress] = useState(0);
  const longPressTimer = useRef(null);
  const minimize_limit = 160;
  const minimize_size = 90;
  const contentRef = useRef(null);
  const [initialTouchPos, setInitialTouchPos] = useState({ x: 0, y: 0 });
  const [windowSize, setWindowSize] = useState({
    width: initial_width,
    height: start_collapsed ? minimize_size : initial_height,
  });

  const [windowPosition, setWindowPosition] = useState({
    x: initial_position_x,
    y: initial_position_y,
  });

  // const adjustWindowPosition = () => {
  //   const windowWidth = window.innerWidth;
  //   const windowHeight = window.innerHeight;

  //   setWindowPosition((prevPos) => {
  //     let newX = prevPos.x;
  //     let newY = prevPos.y;

  //     if (newX + windowSize.width > windowWidth) {
  //       newX = windowWidth - windowSize.width;
  //     }
  //     if (newY + windowSize.height > windowHeight) {
  //       newY = windowHeight - windowSize.height;
  //     }

  //     if (newX < 0) {
  //       newX = 0;
  //     }
  //     if (newY < 0) {
  //       newY = 0;
  //     }

  //     return { x: newX, y: newY };
  //   });
  // };

  // useEffect(() => {
  //   const handleResize = () => {
  //     adjustWindowPosition();
  //   };

  //   window.addEventListener("resize", handleResize);

  //   adjustWindowPosition();

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, [windowSize]);

  const toggleMinimize = () => {
    if (minimize_callback) {
      minimize_callback();
    } else {
      setIsMinimized(!isMinimized);
      if (isMinimized) {
        const contentHeight = contentRef.current?.scrollHeight || initial_height;
        setWindowSize({
          width: initial_width,
          height: Math.min(contentHeight + 60, max_height),
        });
      } else {
        setWindowSize({
          width: windowSize.width,
          height: minimize_size,
        });
      }
    }
  };

  const handleTouchStart = (e) => {
    if (!enable_minimize) return;
    setIsDragging(false);
    setInitialTouchPos({
      x: e.touches[0].clientX,
      y: e.touches[0].clientY,
    });
    setShowProgressBar(true);
    setProgress(0);

    longPressTimer.current = setTimeout(() => {
      setProgress(100);
      toggleMinimize();
    }, 1000);

    let increment = 0;
    const interval = setInterval(() => {
      increment += 12;
      setProgress(increment);
      if (increment >= 100 || isDragging) {
        clearInterval(interval);
      }
    }, 100);
  };

  const handleTouchMove = (e) => {
    const moveX = e.touches[0].clientX;
    const moveY = e.touches[0].clientY;

    const distanceMoved = Math.sqrt(
      Math.pow(moveX - initialTouchPos.x, 2) + Math.pow(moveY - initialTouchPos.y, 2)
    );

    // Si el usuario se mueve más de 10 píxeles, cancela la pulsación larga
    if (distanceMoved > 10) {
      setIsDragging(true);
      clearTimeout(longPressTimer.current); // Cancela la acción de minimización
      setShowProgressBar(false);
    }
  };

  const handleTouchEnd = () => {
    if (!enable_minimize) return;
    clearTimeout(longPressTimer.current);
    setShowProgressBar(false);
    setProgress(0);
  };
  const handleDragStart = () => {
   // console.log("Drag start")
    setIsDragging(true);
    clearTimeout(longPressTimer.current); // Cancelamos el long press si empieza a arrastrar
    setShowProgressBar(false);
  };

  const resizeDirections = {
    bottom: enable_resize,
    bottomLeft: enable_resize,
    bottomRight: enable_resize,
    left: enable_resize,
    right: enable_resize,
    top: enable_resize,
    topLeft: enable_resize,
    topRight: enable_resize,
  };

  return (
    <Rnd
      default={{
        x: initial_position_x,
        y: initial_position_y,
        width: initial_width,
        height: start_collapsed ? minimize_size : initial_height,
      }}
      size={{ width: windowSize.width, height: windowSize.height }}
      position={{ x: windowPosition.x, y: windowPosition.y }}
    //  onDragStart={handleDragStart}
      onDragStop={(e, d) => {
       // console.log("Drag stop")
        setWindowPosition({ x: d.x, y: d.y });
      //  setIsDragging(false);
      }}

      onResizeStop={(e, direction, ref, delta, position) => {
        if (enable_minimize) {
          let height = parseInt(ref.style.height.replace("px", ""));
          if (height > minimize_limit) {
            setIsMinimized(false);
          }
          if (height < minimize_limit) {
            setIsMinimized(true);
          }
        }

        setWindowSize({
          width: ref.style.width,
          height: ref.style.height,
        });
      }}
      
      minWidth={200}
      minHeight={minimize_size}
      enableResizing={resizeDirections}
      bounds="window"
      maxHeight="100vh"
      maxWidth="100vw"
      dragHandleClassName="drag-handle"
      style={{
        backdropFilter: "blur(10px)",
        backgroundColor:
          "color-mix(in srgb, rgba(var(--color-primary-raw), 0.5) 20%, rgba(0, 0, 0, 0.5) 80%)",
        color: "white",
        borderRadius: "10px",
        padding: "1rem",
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
        zIndex: zIndex,
      }}
    >
      <div style={{ position: "relative", height: "100%", width: "100%", maxWidth:"100vw", maxHeight:"100vh" }}>
        <Tooltip title={isMobile?"Pulsación larga para achicar o agrandar":"Doble click para agrandar o achicar"}>
          <Paper
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onTouchMove={handleTouchMove}
            style={{
              padding: "1rem",
              paddingBottom:"0.5rem",
              paddingTop:"0.5rem",
              display: "flex",
              boxSizing:"border-box",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "grab",
              position: "relative", // Para la barra de progreso
            }}
            className={enable_drag ? "drag-handle" : ""}
            onDoubleClick={()=>{
              if(enable_minimize) toggleMinimize()}}
          >
            <h4 style={{ margin: 0 }}>{title}</h4>
            <div>
              {allow_maximize && 
                  <button
                  onClick={(evt)=>{
                    evt.preventDefault()
                    setWindowSize({
                      width: window.innerWidth,
                      height: window.innerHeight,
                    })
                    setWindowPosition({ x: 0, y: 0 });
                  }}
                  onTouchStart={(e) => {
                    e.stopPropagation(); // Evita que el evento sea capturado por el drag
                    e.preventDefault();
                    setWindowSize({
                      width: windowSize.width,
                      height:windowSize.height
                    })
                    setWindowPosition({ x: 0, y: 0 });
                  }}
                  style={{
                    background: "none",
                    border: "none",
                    fontSize: "1.2rem",
                    cursor: "pointer",
                    paddingBottom: "0.1rem",
                  }}
                >
                  <img alt="maximize button" src={ process.env.PUBLIC_URL + "/images/icons/maximize-svgrepo-com.svg"} style={{height:"1.1rem"}}/>

                </button>
              }
            {enable_minimize && 
            <IconButton
              onClick={(evt)=>{
                evt.stopPropagation(); // Evita que el evento sea capturado por el drag
                evt.preventDefault();
                toggleMinimize();
              }}
              onTouchStart={(e) => {
                e.stopPropagation(); // Evita que el evento sea capturado por el drag
                e.preventDefault();
                toggleMinimize(e);
              }}
              style={{
                background: "none",
                border: "none",
                fontSize: "1.2rem",
                cursor: "pointer",
                color:"black"
              }}
            >
            {isMinimized ? <UnfoldMoreIcon/>:<UnfoldLessIcon/>}
            </IconButton>
          }
              {onClose && (
                <button
                  onClick={onClose}
                  onTouchStart={(e) => {
                    e.stopPropagation(); // Evita que el evento sea capturado por el drag
                    e.preventDefault();
                    onClose(e);
                  }}
                  style={{
                    background: "none",
                    border: "none",
                    fontSize: "1.2rem",
                    cursor: "pointer",
                    paddingBottom: "0.1rem",
                  }}
                >
                  X
                </button>
              )}
            </div>
            {showProgressBar && (
              <LinearProgress
                variant="determinate"
                value={progress}
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: "5px",
                }}
                sx={{
                  backgroundColor: 'var(--color-primary-light-80)',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: 'var(--color-primary-light-40)'
                  }
                }}
              />
            )}
          </Paper>
        </Tooltip>
        {isMinimized && enable_minimize ? null : (
          <>
            <div
              ref={contentRef}
              id="style-1"
              style={{
                paddingTop: "1rem",
                overflow: "auto",
                //paddingBottom: "2rem",
                height: isMobile?"calc(100% - 8rem)": "calc(100% - 4rem)",
                width: "calc(100% - 1rem)",
              }}
            >
              {content}
            </div>
            <div
              style={{
                position: "absolute",
                bottom: "-13px",
                left: "-13px",
                width: "30px",
                height: "30px",
                cursor: "nwse-resize",
                borderLeft: "2.5px solid rgba(0, 0, 0, 0.5)",
                borderBottom: "2.5px solid rgba(0, 0, 0, 0.5)",
              }}
            />
            <div
              style={{
                position: "absolute",
                bottom: "-8px",
                left: "-8px",
                width: "20px",
                height: "20px",
                cursor: "nwse-resize",
                borderLeft: "2px solid rgba(0, 0, 0, 0.5)",
                borderBottom: "2px solid rgba(0, 0, 0, 0.5)",
              }}
            />
          </>
        )}
      </div>
    </Rnd>
  );
};

export { FloatingWindow };
