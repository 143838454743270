import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { toolbarItems } from "../ToolbarItems/ToolbarItems";
import "./Header.css";

import MapSearcher from "../../CustomComponents/MapSearcher/MapSearcher";
import { ToolsDrawer } from "./Drawer";
import { ToolsSection } from "./ToolsSections";

export default function Header({
  style = {},
  className = "",
  enableToolbar = false,
  enableSearchBar = false,
}) {
  const [openDrawer, setOpenDrawer]     = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const isSmallScreen                   = useMediaQuery("(max-width:600px)");
  const isVerySmallScreen               = useMediaQuery("(max-width:370px)");

  const handleIconClick = (item) => {
    if (item.component) {
      setSelectedItem(item);
      setOpenDrawer(true);
    } else if (item.action) {
      item.action();
    }
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
    setSelectedItem(null);
  };

  return (
    <>
      <AppBar
        position="static"
        className={`header ${className}`}
        style={{ backgroundColor: "var(--color-primary)", ...style }}
      >
        <Toolbar>
          {!isVerySmallScreen && <a
            href="https://www.agforest.ai"
            className="header_logo"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="images/BLANCO.png"
              alt="logo"
              className="header_logo_img"
            />
          </a>}
          {isSmallScreen ? 
            <>
              {enableSearchBar && <MapSearcher />}
              {!enableToolbar ? (
                <></>
              ) :  (
                <IconButton
                  edge="end"
                  onClick={() => setOpenDrawer(true)}
                  style={{ marginLeft: "auto" }}
                >
                  <MenuIcon style={{ color: "var(--color-primary-txt)" }} />
                </IconButton>
              ) 
             }
            </>: <div style={{display:"flex",width:"100%", justifyContent:"space-between", marginLeft:"1rem"}}>
              {enableToolbar && <ToolsSection position={undefined} handleIconClick={handleIconClick}/>}
              <div style={{display:"flex", flexDirection:"row", width:"40vw", maxWidth:"50vw", justifyContent:"space-between"}}>
              {enableSearchBar && <MapSearcher />}
              {enableToolbar && <ToolsSection position={"end"} handleIconClick={handleIconClick}/>}
              </div>
             </div>
          }
        </Toolbar>
      </AppBar>

      <ToolsDrawer
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        handleDrawerClose={handleDrawerClose}
        handleItemClick={handleIconClick}
      />
    </>
  );
}
