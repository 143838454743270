import { createEmpty, extend } from "ol/extent";
import GeoJSON from 'ol/format/GeoJSON.js';

const get_aoi_extent = async(WFSHost,capaWFS,filtroCQL) =>{
    //const host = 'https://api.asbestos.ai/geoserver/ows?service=WFS&version=2.0.0&request=GetFeature&outputFormat=application/json&srsname=EPSG:4326';
    const wfsUrl = WFSHost+`&typeName=${capaWFS}&CQL_FILTER=${filtroCQL}`;
    
    return fetch(wfsUrl)
      .then(response => response.json())
      .then(data => {
        
        const features = new GeoJSON().readFeatures(data, {
          dataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:4326'
        });
  
        const extent = createEmpty();
        features.forEach(feature => {
          extend(extent, feature.getGeometry().getExtent());
        });
  
        return extent;
      })
      .catch(error => {
        console.error('Error al obtener las características WFS:', error);
        return null;
      });
}

const center_map_from_extent = (map, extent)=>{
    map.getView().fit(extent, {
        size:map.getSize(),
        padding: [100, 100, 150, 100]
    });
}

const getCurrentAoiUuid = ()=>{ // TODO
    return new Promise((resolve) => {
        setTimeout(() => {
          resolve( "e1f83f7c-3fdb-4920-952e-eeac592157dc");
        }, 100); // Retraso de 100ms
      });

}
export {get_aoi_extent, center_map_from_extent, getCurrentAoiUuid}