import React, { useState, useEffect, useContext } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { setLayerVisibility } from "../../../../../services/MapCore/LayerGroupService";
import AppContext from "../../../../Contexts/AppContext";
import { LayerSettingsElement } from "./LayerSettingsElement";

const LayerGroupSettingsElement = ({ layerGroup }) => {
  const [layerVisibilityControl, setLayerVisibilityControl] = useState(
    layerGroup.visible
  );

  const {
    generalConfigParams,
    setGeneralConfigParams,
    layersGroups,
    setLayersGroups,
    clickViews,
    setClickViews,
    map,
    aoiUuid,
    setAoiUuid,
    aoiExtent,
    setAoiExtent,
    isWindowFeatureDetailsOpen,
    setIsWindowFeatureDetailsOpen,
  } = useContext(AppContext);

  useEffect(() => {
    // Inicializar el estado de visibilidad de las capas
    const initialVisibility = {};
    layerGroup.layers.forEach((layer) => {
      initialVisibility[layer.id] = layer.visible;
    });
    setLayerVisibilityControl(initialVisibility);
  }, [layerGroup.layers]);

  const handleToggleLayerVisibility = (layerId) => {
    const newVisibility = !layerVisibilityControl;
    setLayerVisibilityControl(newVisibility);
    // Llamar al callback si se proporciona
    setLayerVisibility(map, layerId, newVisibility);
  };

  return (
    <>
      <Divider style={{borderColor:"rgba(255, 255, 255, 0.4)", paddingTop:"1rem" }} variant="middle" />
      <div style={{ paddingLeft: "2rem", paddingTop: "1rem" }}>
        <div style={{ marginBottom: "0rem" }}>
          <Accordion
            sx={{
              backgroundColor: "var(--color-bg-3)",
              color: "var(--color-txt-3)",
              borderRadius: 2,
              "&:before": {
                display: "none",
              },
              "& .MuiAccordionSummary-root": {
                borderRadius: 2,
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{ display: "flex", alignItems: "center" }}
              style={{ display: "flex", alignItems: "center" }}
            >
              <IconButton
                style={{ paddingRight: "1rem" }}
                onClick={(e) => {
                  e.stopPropagation(); // Prevenir que el acordeón se despliegue al hacer click en el botón
                  handleToggleLayerVisibility(layerGroup.id);
                }}
                edge="start"
                aria-label="toggle visibility"
              >
                {layerVisibilityControl ? (
                  <VisibilityIcon />
                ) : (
                  <VisibilityOffIcon />
                )}
              </IconButton>
              <Typography style={{display:"flex", alignItems:"center"}}>{layerGroup.name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {layerGroup.layers.map((layer) => {
                return <LayerSettingsElement layer={layer} />;
              })}
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </>
  );
};

export { LayerGroupSettingsElement };
