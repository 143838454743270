import { MenuItem, Select } from "@mui/material"
import AppContext from "../../../Contexts/AppContext"
import { useContext } from "react"



export const LayerStyleSelector = ({callback=()=>{}, styles_aviables=[], default_style_id=false})=>{
    const {updateLegend} = useContext(AppContext)
    const defaultStyleIdValue = default_style_id? default_style_id : styles_aviables[0].id;
    return <Select
    onChange={(e)=>{
      callback(e);
      updateLegend()
   }}
    defaultValue={defaultStyleIdValue}
    >
       {styles_aviables.map((style, index)=>{
          return <MenuItem value={style.id}>{style.label}</MenuItem>
       })}
       
    </Select>
}