
import * as React  from 'react';

import { Line } from 'react-chartjs-2';
import {Chart as ChartJS} from "chart.js";
import 'chart.js/auto';
import zoomPlugin from "chartjs-plugin-zoom";
ChartJS.register(
  zoomPlugin
);


const GraphAmortizationComponent = ({initialCost, maintenanceRateCost, annualSavings, amortization_time}) => {
   
    const years = Array.from({ length: 15 }, (_, i) => i + 1);
    const savings = years.map(year => parseInt(annualSavings) * (year));
    const maintenanceCosts = years.map(year => parseInt(maintenanceRateCost) * year);
    const totalCosts = years.map(year => parseInt(initialCost) + maintenanceCosts[year - 1]);
    const data = {
        labels: years,
        datasets: [
            {
                label: 'Coste Inicial + Mantenimiento (€)',
                data: totalCosts,
                borderColor: 'rgba(255, 99, 132, 1)',
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                fill: false,
            },
            {
                label: 'Ahorro Acumulado (€)',
                data: savings,
                borderColor: 'rgba(54, 162, 235, 1)',
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                fill: false,
            },
        ],
    };
  
    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        plugins: {
            tooltip: {
                enabled: true,
            },
        },
    };
  
    return (
        <div style={{display:"flex", flexDirection:"column",paddingLeft:"1rem", textAlign:"left",justifyContent:"flex-start", boxSizing:"border-box"}}>
            <h2>Coste Inicial vs Amortización Energética</h2>
            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between",  width:"100%", maxWidth:"30rem"}}><label>Coste inicial:</label><label> {parseInt(initialCost)}€</label></div>
            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between",  width:"100%", maxWidth:"30rem"}}><label>Coste anual por mantenimiento:</label><label> {parseInt(maintenanceRateCost)}€</label></div>
            <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between",  width:"100%", maxWidth:"30rem"}}><label>Ahorro anual estimado:</label><label> {parseInt(annualSavings)}€</label></div>
            <h4 style={{paddingTop:"0.5rem", paddingLeft:"1rem"}}>Tiempo de amortización estimado  <strong style={{marginLeft:"1rem",borderRadius:"1rem", padding:"1rem", backgroundColor:"var(--color-primary-light-90)", fontSize:"1.3rem"}}>{"  >"}{amortization_time-1} años</strong></h4>
            <Line data={data} options={options} />
        </div>
    );
  };
  

  export {GraphAmortizationComponent}