import React, { useContext, useState } from "react";
import AppContext from "../../../../Contexts/AppContext";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  IconButton,
  Slider,
  Divider,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  setLayerOpacity,
  setLayerVisibility,
} from "../../../../../services/MapCore/LayerGroupService";

const LayerSettingsElement = ({ layer }) => {
  const { map } = useContext(AppContext);
  const [layerVisibilityControl, setLayerVisibilityControl] = useState(
    layer.visible
  );
  const [layerTransparencyControl, setLayerTransparencyControl] = useState(
    (1 - layer.opacity) * 100
  );
  const handleToggleLayerVisibility = (layerId) => {
    const newVisibility = !layerVisibilityControl;
    setLayerVisibilityControl(newVisibility);
    setLayerVisibility(map, layerId, newVisibility);
  };

  const handleTransparencyChange = (event, newValue) => {
    setLayerTransparencyControl(newValue);
    // Actualizar la opacidad de la capa en el mapa
    const opacity = 1 - newValue / 100;
    setLayerOpacity(map, layer.id, opacity);
  };

  const style_selector = layer.getChangeStyleComponent();
  return (
    <Accordion key={layer.id} sx={{ marginBottom: "0.5rem" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ display: "flex", alignItems: "center" }}
        style={{ display: "flex", alignItems: "center" }}
      >
        <IconButton
          onClick={(e) => {
            e.stopPropagation(); // Prevenir que el acordeón se despliegue al hacer click en el botón
            handleToggleLayerVisibility(layer.id);
          }}
          edge="start"
          aria-label="toggle visibility"
        >
          {layerVisibilityControl ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </IconButton>
        <Typography sx={{ display: "flex", alignItems: "center" }}>
          {layer.name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Divider style={{marginBottom:"0.5rem"}}/>
        {style_selector && <div>
          <Typography variant="subtitle1">
            Selector de visualización
           </Typography>
          {style_selector}
          <Divider style={{margin:"1rem"}}/>
          </div>}
        <div>
          {/* Título del slider */}
          <Typography variant="subtitle1">
            Transparencia: {Math.round(layerTransparencyControl)}%
          </Typography>
          <Slider
            value={layerTransparencyControl}
            onChange={handleTransparencyChange}
            aria-labelledby="transparency-slider"
            min={0}
            max={100}
            step={1}
            sx={{
              color: "var(--color-primary)",
            }}
          />
          {layer.controlSettingsComponent}
        </div>
        
      </AccordionDetails>
    </Accordion>
  );
};

export { LayerSettingsElement };
