import axios from "axios";

export const get_report = async (
    ineCode,
    userHeader,
    template_id,
    token
  ) => {
    let URL_BASE =
      process.env.REACT_APP_API_URL +
      "amiantic/v1.0/api/microservices/comercials/";
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: URL_BASE + "createPDF_V2/" + ineCode + "/" + template_id,
      headers: {
        us: userHeader,
        Authorization: token,
        "Content-Type": "*",
        "Access-Control-Allow-Headers": "*",
        Accept: "*/*",
      },
    };
    return axios
      .request(config)
      .then((response) => {
        let data = response.data;
        return data;
      })
      .catch((error) => {
        // console.log(error);
        return false;
      });
  };