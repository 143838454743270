import { Button, Divider, Paper } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../../../../Contexts/AppContext";
import { buildCQLFilter } from "../../../../../../services/MapCore/MapCQLFilterService";
import {
  ColumnChart,
  DonutChart,
  getCountDictionary,
  LineChart,
} from "../../../../../../services/GraphService";
import BarChartSkeleton from "../../../../../Skeletons/BarChartSkeleton";
import {ExpandableTable, generateColumnsFromData} from "../../../../../CustomComponents/ExpandableTable/ExpandableTable";
import DictionaryTabs from "../../../../../CustomComponents/DictionaryTabs/DictionaryTabs";
import {MiniMap} from "../../../../Minimap/Minimap"
import { GraphAmortizationComponent } from "../Components/GraphAmortizacion";
import { ErrorBoundary } from "../../../../../CustomComponents/ErrorBoundary/ErrorBoundary";
import ExportExcel from "../../../../../../services/Commons/ExcelExport";
import Notiflix from "notiflix";
import authService from "../../../../../../services/AuthService";
import { get_report } from "../../../../../../services/Commons/PDFService";
const DashboardSolar = ({ layerGroup }) => {
  const [isLoading, setIsLoading]           = useState(true);
  const [features, setFeatures]             = useState([]);
  //const [rawFeatures, setRawFeatures]             = useState([]);
  const [usoCount, setUsoCount]             = useState({});
  const [titularCount, setTitularCount]     = useState({});
  const [nivelRiesCount, setNivelRiesCount] = useState({});
  const [mediaAmortizacionXUso, setMediaAmortizacionXUso] = useState({});
  const [mediaAmortizacionXRiesgo, setMediaAmortizacionXRiesgo] = useState({});
  const [pageSize, setPageSize]             = useState(10);
  const [page, setPage]                     = useState(0);
  let lastDate                              = new Date();
  
  const {
    generalConfigParams,
    setGeneralConfigParams,
    layersGroups,
    setLayersGroups,
    clickViews,
    setClickViews,
    map,
    mapInstanceRef,
    aoiUuid,
    aoiExtent,
    dashboardsToShow,
    setDashboardsToShow,
  } = useContext(AppContext);
  //let response = null
  const handleDownloadPdf = async (template, provider_name = "") => {
    //console.log("features", features)
    if (features && features.length > 0 &&  "CodINE" in features[0]) {
      Notiflix.Loading.circle();
      const ineCode = features[0]["CodINE"];
      const name = features[0]["Municipio"];
      const filename = provider_name + "Informe exploratorio amianto_Ayto. " + name + ".pdf";
      const signedUrl = await get_report(
        ineCode,
        "",
        template,
        authService.getToken()
      );
      if (signedUrl) {
        try {
          const response = await fetch(signedUrl);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const blob = await response.blob();
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } catch (error) {
          console.error('Error downloading the file:', error);
          Notiflix.Notify.failure('Error al descargar el archivo');
        }
      }
      Notiflix.Loading.remove();
    } else {
      Notiflix.Notify.warning("Seleccione un municipio válido");
    }
  };
  const getFeaturesFromWFS = async (cqlFilter) => {
    try {
      // Construye la URL con el filtro CQL
      const url =
        "https://api.asbestos.ai/geoserver/ows?" +
        "service=WFS&" +
        "version=2.0.0&" +
        "request=GetFeature&" +
        "typename=agforest:poc_solar_amortization&" + // Nombre de la capa WFS
        "outputFormat=application/json&" +
        "srsname=EPSG:4326&" +
        "CQL_FILTER=" +
        encodeURIComponent(cqlFilter);

      // Realiza la solicitud fetch
      // if (!response){
      //   response = await fetch(url);
      // }
      const response = await fetch(url);

      // Verifica si la respuesta fue exitosa
      if (!response.ok) {
        throw new Error(`Error en la solicitud WFS: ${response.statusText}`);
      }

      // Obtén los datos en formato JSON
      const data = await response.json();
      console.log("Datos obtenidos", data);
      // Retorna las features como una lista
      return data.features;
    } catch (error) {
      console.error("Error al obtener las features del WFS:", error);
      return []; // Retorna una lista vacía en caso de error
    }
  };
  function calcularMediaPorCategoria(lista, claveCategoria, claveValor) {
    // Creamos un objeto para almacenar las sumas y conteos por categoría
    const categorias = {};
  
    // Iteramos sobre la lista de objetos
    lista.forEach((item) => {
      const categoria = item[claveCategoria];
      const valor = item[claveValor];
     
    
      // Verificamos si la categoría ya existe en el objeto
      if (!categorias[categoria]) {
        categorias[categoria] = { suma: 0, count: 0 };
      }
  
      // Acumulamos la suma y el conteo de elementos
      categorias[categoria].suma += valor;
      categorias[categoria].count += 1;
    });
   // console.log("Recogida de datos", categorias, Object.keys(lista[0]))
    // Creamos un objeto final donde las claves son las categorías
    // y los valores son las medias correspondientes
    const mediasPorCategoria = {};
  
    Object.keys(categorias).forEach((categoria) => {
      const { suma, count } = categorias[categoria];
      mediasPorCategoria[categoria] = suma / count;
    });
  
    return mediasPorCategoria;
  }
  
  const loadFeatures = async () => {
    let currentDate = new Date();
    lastDate = currentDate
    const use_local_filtering = true
    const data  = []
    
    if(use_local_filtering){
       const raw = layerGroup.heatmapLayer.filteredFeatures
       raw.forEach(e=>data.push(e.getProperties()));
       //setRawFeatures(raw)
    }else{
      const raw = await getFeaturesFromWFS(buildCQLFilter(layerGroup.filters))//.forEach(e=>data.push(e.getProperties()));
      raw.forEach(e=>data.push(e.getProperties()));
      //setRawFeatures(raw)
    }

    
    console.log("Datos obtenidos!!, data", data)
    if(!use_local_filtering && lastDate != currentDate) return

    setFeatures(data);
    let filtered_data = getCountDictionary(data, "Uso");
    if(Object.keys(filtered_data).includes(undefined)){
      delete filtered_data[undefined]
    }
    console.log("Datos procesados..Uso", filtered_data)
    setUsoCount(filtered_data);



    if(!use_local_filtering && lastDate != currentDate) return

    filtered_data = getCountDictionary(data, "Titular");
    if(Object.keys(filtered_data).includes(undefined)){
      delete filtered_data[undefined]
    }
    console.log("Datos procesados..Titular", filtered_data)
    setTitularCount(filtered_data);
    if(!use_local_filtering && lastDate != currentDate) return

    filtered_data = getCountDictionary(data, "Nivel_ries");
    if(Object.keys(filtered_data).includes(undefined)){
      delete filtered_data[undefined]
    }
    console.log("Datos procesados..Nivel_ries", filtered_data)
    setNivelRiesCount(filtered_data);

    console.log("Datos procesados..")
    if(!use_local_filtering && lastDate != currentDate) return

    
    filtered_data = calcularMediaPorCategoria(data, "Uso", "solar_amortizacion_years");
    if(Object.keys(filtered_data).includes(undefined)){
      delete filtered_data[undefined]
    }
    for(const k in filtered_data){
      filtered_data[k] = filtered_data[k].toFixed(2)
    }
    console.log("Datos procesados..mediaAmortizacionXUso", filtered_data)
    setMediaAmortizacionXUso(filtered_data);
    if(!use_local_filtering && lastDate != currentDate) return

    filtered_data = calcularMediaPorCategoria(data, "Nivel_ries", "solar_amortizacion_years");
    if(Object.keys(filtered_data).includes(undefined)){
      delete filtered_data[undefined]
    }
    for(const k in filtered_data){
      filtered_data[k] = filtered_data[k].toFixed(2)
    }
    console.log("Datos procesados..setMediaAmortizacionXRiesgo", filtered_data)
    setMediaAmortizacionXRiesgo(filtered_data);
    if(!use_local_filtering && lastDate != currentDate) return

 
    setIsLoading(false);
  };
  useEffect(() => {
    setIsLoading(true);
    loadFeatures();
    document.addEventListener("update-filter-"+layerGroup.id,()=>{
      setIsLoading(true);
      loadFeatures()
      
    } )
    
    // obtener datos
    return ()=>{
      document.removeEventListener("update-filter-"+layerGroup.id,loadFeatures )
    }
    //setIsLoading(false);
  }, []);

  const delete_key_from_array_of_obj = (data, keys_to_delete)=>{
    Notiflix.Loading.circle("Preparando datos...")
    const dt = data.map(d=>{
      for(const key in Object.keys(d)){
        if(keys_to_delete.includes(key)){
          delete d[key]
        }
      }
      return d
    });
    Notiflix.Loading.remove()
    return dt
  }
  return (
    <Paper style={{width:"100%", display:"flex", flexDirection:"column", gap:"1rem", justifyContent:"center",alignItems:"center", paddingTop:"1rem", paddingBottom:"1rem", overlayX:"hidden"}}>
      <div style={{display:"flex", width:"100%", flexWrap:"wrap", justifyContent:"center", alignItems:"center", paddingRight:"1rem", paddingLeft:"1rem", boxSizing:"border-box"}}>
      <h3 style={{flex:"4 1 200px", paddingBottom:"0rem"}}>Dashboard de información solar</h3>
      <Button 
      style={{
        flex:"1 1 130px",
        backgroundColor:"var(--color-secondary)",
        color:"var(--color-secondary-txt)"
      }}
      onClick={(evt)=>{
        evt.preventDefault();
        evt.stopPropagation();
        handleDownloadPdf("fec77b2348541812", "Agforest_");
      }}>Descargar reporte</Button>
        </div>
      <label style={{backgroundColor:"#cdcdcd", borderRadius:"1rem", padding:"1rem", boxSizing:"border-box", width:"100%",justifyContent:"center",alignItems:"center",display:"flex", border:"1px solid var(--color-secondary)"}}>
        Los filtros  seleccionados en la sección de capas también se aplican a los datos visualizados en esta sección
      </label>
      <DictionaryTabs tabsData={{
        "Gráficas":<div style={{width:"90%", display:"flex", flexDirection:"row", gap:"1rem", flexWrap:"wrap", justifyContent:"center", padding:"1rem", paddingBottom:"5rem"}}>
        {/* {!isLoading ? (
         <Paper
           style={{
             width: "100%",
             flex: "1 1 500px",
             minHeight: "300px",
             maxWidth: "700px",
             padding: "10px",
             backgroundColor:"var(--color-primary-light-80) !important"
           }}
         >
          <ErrorBoundary>
           <DonutChart
             categories={Object.keys(nivelRiesCount)}
             series={Object.values(nivelRiesCount)}
             title={
               "Susceptibilidad de las edificaciones de contener amianto."
             }
           />
           </ErrorBoundary>
         </Paper>
         
       ) : (
         <>
           <BarChartSkeleton />
         </>
       )}  */}
        {!isLoading ? (
         <Paper
           style={{
             width: "100%",
             flex: "1 1 500px",
             minHeight: "300px",
             maxWidth: "700px",
             padding: "10px",
             backgroundColor:"var(--color-primary-light-80) !important"
           }}
         >
          <ErrorBoundary>
           <ColumnChart
             categories={Object.keys(titularCount)}
             series={Object.values(titularCount)}
             title={"Edificios por titularidad"}
           />
           </ErrorBoundary>
         </Paper>
       ) : (
         <>
           <BarChartSkeleton />
         </>
       )}
       {!isLoading ? (
        <Paper style={{width: "100%",flex: "1 1 500px",minHeight: "300px",maxWidth: "700px",padding:"10px", backgroundColor:"var(--color-primary-light-80) !important"}}>
               <ErrorBoundary><ColumnChart isHorizontal={false} colors={["#66c2a5","#fc8d62","#8da0cb","#e78ac3","#a6d854","#ffd92f","#e5c494"]} 
               categories={Object.keys(usoCount)} series={Object.values(usoCount)} title={"Cantidad de edificaciones por tipología de uso."}/></ErrorBoundary>
             </Paper> 
         ): (
           <>
             <BarChartSkeleton />
           </>
         )}

{!isLoading ? (
        <Paper style={{width: "100%",flex: "1 1 500px",minHeight: "300px",maxWidth: "700px",padding:"10px", backgroundColor:"var(--color-primary-light-80) !important"}}>
               <ErrorBoundary><ColumnChart isHorizontal={true} colors={["#66c2a5","#fc8d62","#8da0cb","#e78ac3","#a6d854","#ffd92f","#e5c494"]} 
               categories={Object.keys(mediaAmortizacionXUso)} series={Object.values(mediaAmortizacionXUso)} title={"Media de años para la amortización de la instalación solar por tipo de uso."}/></ErrorBoundary>
             </Paper> 
         ): (
           <>
             <BarChartSkeleton />
           </>
         )}

{!isLoading ? (
        <Paper style={{width: "100%",flex: "1 1 500px",minHeight: "300px",maxWidth: "700px",padding:"10px", backgroundColor:"var(--color-primary-light-80) !important"}}>
               <ErrorBoundary><ColumnChart isHorizontal={false} colors={["#66c2a5","#fc8d62","#8da0cb","#e78ac3","#a6d854","#ffd92f","#e5c494"]} 
               categories={Object.keys(mediaAmortizacionXRiesgo)} series={Object.values(mediaAmortizacionXRiesgo)} title={"Media años amortización de solar / susceptibilidad de contener amianto."}/></ErrorBoundary>
             </Paper> 
         ): (
           <>
             <BarChartSkeleton />
           </>
         )}
     </div>,
        "Tabla":<Paper style={{width: "100%",flex: "1 1 500px",minHeight: "300px",maxWidth: "100%",padding:"0", backgroundColor:"var(--color-primary-light-80) !important"}}>
          {!isLoading ?<>
          <div style={{width:"100%",justifyContent:"flex-start", display:"flex", alignItems:"flex-start"}}>
            <ExportExcel data={delete_key_from_array_of_obj(features, ["geometry"])}/>
          </div>
          <ExpandableTable page={page} setPage={setPage} data={features?features.slice(page*pageSize, (page*pageSize) +pageSize ):[]} numberOfElements={features.length} pageSize={pageSize}  setPageSize={setPageSize} columns={features.length>0?generateColumnsFromData(features, ["geometry"]):[]} updatePage={(new_page)=>{}} ExpandedRowComponent={(row)=>{return     (
            <div style={{backgroundColor:"var(--color-secondary-light-90)", padding:"2rem", borderRadius:"1rem"}}>
              <h3>Detalles de la selección</h3>
              <h4>Mapa de la edificación</h4>
              <div style={{ width: '100%', maxWidth:"600px", height: '300px' }}>
                <MiniMap props={row} name={row["RefCatast"]}/>
              </div>
              <h4>Imagen de la edificación</h4>
              <div >
                <a href={row["Link_image"]} target="_blank"><img src={row["Link_image"]} alt={"Imagen del edificio"}  style={{ width: '100%', maxWidth:"600px", height: '300px' }}/></a>
              </div>
              <h4>Gráfica de amortización solar</h4>
              <Paper style={{paddingBottom:"3rem", paddingTop:"1rem",maxWidth:"600px", width:"100%"}}>
                <GraphAmortizationComponent maintenanceRateCost={row["coste_solar_mantenimiento_anual"]} annualSavings={row["SolAhorro"]} initialCost={row["coste_solar_inicial_total"]} amortization_time={row["solar_amortizacion_years"]} />
              </Paper>
    </div>)}} id="RefCatast"  /></>: <BarChartSkeleton />}
          </Paper> 
      }}/>
    </Paper>
  );
};

export { DashboardSolar };
