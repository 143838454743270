
import * as React  from 'react';
import LayerPublicAmianticTextInput from "../LayerPublicAmianticTextInput"
import "./LayerPublicAmiantic.css"
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  
} from "chart.js";
import 'chart.js/auto';
import {  getCenter } from "ol/extent";
//import {transform} from "ol/proj";
import axios from 'axios';
import zoomPlugin from "chartjs-plugin-zoom";
ChartJS.register(
  zoomPlugin
);
// Suponiendo que `data` es el objeto JSON que has proporcionado
const GraphComponent = ({ data }) => {
   const options = {
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
       // title:  "Radiación (kWh/m²/mes)",
       title: {
          display: true,
          text:  "Radiación (kWh/m²/mes)",
          //fontColor: "#131313",
        },
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        title: {
          display: true,
          text:  "Temperatura ºC",
         // fontColor: "#131313",
        },
       
        grid: {
          drawOnChartArea: false, // Solo dibujar la grilla para el eje Y principal
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      zoom: {
        zoom: {
          // drag: {
          //   enabled: true,
          //   backgroundColor: 'rgba(225,225,225,0.3)',
          // },
          wheel: {
            enabled: true, // Deshabilita el zoom con la rueda del ratón para evitar conflictos
          },
          pinch: {
            enabled: true
          },
          mode: "x"
        },
        pan: {
          enabled: true, // Habilita el paneo (movimiento) con el arrastre del ratón
          mode: 'x', // Permite panear solo en el eje X
        },
        
        
      },
    },
  };
  const chartData = {
    labels: data.outputs.monthly.map(d => `${d.year}-${d.month.toString().padStart(2, '0')}`),
    datasets: [
      {
        label: 'Irradiación en plano horizontal (kWh/m²/mo)',
        data: data.outputs.monthly.map(d => d['H(h)_m']),
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        yAxisID: 'y',
      },
      {
        label: 'Temperatura media (°C)',
        data: data.outputs.monthly.map(d => d['T2m']),
        borderColor: 'rgb(200, 0, 0)',
        backgroundColor: 'rgba(200, 0, 0, 0.2)',
        yAxisID: 'y1',
      },
      {
        label: 'Radiación solar normal (kWh/m²/mo)',
        data: data.outputs.monthly.map(d => d['Hb(n)_m']),
        borderColor: 'rgb(0, 0, 200)',
        backgroundColor: 'rgba(0, 0, 200, 0.2)',
        yAxisID: 'y',
      },
    ],
  };

  return <Line options={options} data={chartData} />;
};

var InfoField = ({
  label,
  _data,
  fixed_decimals = false,
  is_date = false,
  backgroundColor = false,
  suffix = ""}
) => {

  // console.log(_data);
  if (fixed_decimals && _data !== undefined && _data !== null) {
    //_data = _data.toFixed(fixed_decimals).toString();
    //console.log("_data - ", label, _data)
    _data = new Intl.NumberFormat("es-ES", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,

    }).format(_data).toString();
    //console.log("_data2 - ", label, _data)

  }
  if (is_date && _data !== undefined && _data !== null) {
    _data = new Date(_data).toISOString().slice(0, 10);
  }
  return (

    <LayerPublicAmianticTextInput
      style={{
        width: "90%",
        paddingLeft: "5%",
        // marginTop: "0.8rem",
        backgroundColor: backgroundColor,
      }}
      // sx={{style:{width:"100%"}}}
      // size="small"
      label={label}
      value={"" + _data + suffix}
    //readonly
    />
  );
};

export default function GraphSolar({feature}){
    const [solarData, setSolarData] = React.useState(false)

    const getSolarData = (f)=>{
     // console.log("getSolarData",feature,  f)

      if(Object.keys(feature).length <=0) return;
      //const p = new Point(getCenter(feature.getGeometry().getExtent()))
      // Suponiendo que 'feature' es tu objeto OL Feature de tipo Polygon
      const geometry = feature["geometry"];

      // Calcular el centroide del polígono
      const centroid = geometry["coordinates"][0][0][0];//getCenter(geometry.getExtent());

      // Transformar las coordenadas del centroide a EPSG:4326 si es necesario
      //console.log("centroid", centroid)
     // const lonLat = transform(centroid, 'EPSG:25830', 'EPSG:4326');
     const url = "https://api.asbestos.ai/amiantic/v1.0/api/core/amiantic/getRadiationMonthly/"+centroid[0]+"/"+centroid[1] //+ "?pvgis_tmy=2020"
      //const url = "https://re.jrc.ec.europa.eu/api/v5_1/MRcalc?lat="++"&lon="+centroid[0]+"&elevation=0&pvgis_tmy=2020&outputformat=json&usehorizon=1&userhorizon=&js=1&horirrad=1&optrad=1&mr_dni=1&avtemp=1"
      
      setSolarData(false);
      const fetchData = async (url) => {
        try {
          const response = await axios.get(url, {
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            Accept: "*/*",
          });
          setSolarData(response.data); // Almacenamos los datos en el estado
        } catch (error) {
          console.error("Error al obtener los datos:", error);
          // Manejo opcional del error, por ejemplo, actualizar el estado con un error
          setSolarData(false)
        }
      };
      fetchData(url);
        
    }
    React.useEffect(()=>{
      getSolarData(feature)
    }, [])
   
    return      solarData && solarData.outputs && solarData.outputs.monthly?<>      <label>Haga scroll encima de la gráfica para modificar el zoom</label>
<GraphComponent data={solarData} /></>:<strong>Obteniendo gráfica...</strong>

  
     

}