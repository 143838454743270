import React, { useContext } from "react"
import AppContext from "../../../Contexts/AppContext"
import DictionaryTabs from "../../../CustomComponents/DictionaryTabs/DictionaryTabs";
import { Paper } from "@mui/material";
import { LayerGroupSettingsElement } from "./LayerSettingsElements/LayerGroupSettingsElement";
import {LayerFilterSelectorComponent} from "./LayerFilterSelectorComponent"
const LayerSettingsComponent = ()=>{
    const {
        generalConfigParams, rsetGeneralConfigParams, 
        layersGroups, setLayersGroups,
        clickViews, setClickViews,
        map,
        aoiUuid, setAoiUuid,
        aoiExtent, setAoiExtent,
        isWindowFeatureDetailsOpen, setIsWindowFeatureDetailsOpen
    } = useContext(AppContext);

        return <DictionaryTabs with_gradient={true}
        tabsData={{
            "Visualización":<div style={{display:"flex", flexDirection:"column"}}>
                            <h4>Control de la visualización de las capas</h4>
                            {layersGroups.map((lg)=>{

                                return <LayerGroupSettingsElement layerGroup={lg}/>
                            })}
                            </div>,
             "Filtrado": <LayerFilterSelectorComponent/>,
            //"Filtrado":

                            }}/>
}
export {LayerSettingsComponent}