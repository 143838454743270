import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Style, Icon } from 'ol/style';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { createMarkerLayer } from './MapService';


function addMarkerToMap(map, coordinate) {
  let markerLayer = map
    .getLayers()
    .getArray()
    .find((layer) => layer.get('name') === 'markerLayer');

  if (!markerLayer) {
    markerLayer = createMarkerLayer();
    markerLayer.set('name', 'markerLayer');
    map.addLayer(markerLayer);
  }

  // Limpiar marcadores existentes
  markerLayer.getSource().clear();

  // Crear y añadir el nuevo marcador
  const marker = new Feature({
    geometry: new Point(coordinate),
  });
  markerLayer.getSource().addFeature(marker);
}

export {addMarkerToMap}