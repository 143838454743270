import React, { useState, useEffect } from 'react';
import { DefaultLegendClass } from './DefaultLegendClass';
import { Paper } from '@mui/material';

const WmsLegend = ({ wmsUrl, layerName, rule, style=false }) => {
  const [legendUrl, setLegendUrl] = useState(null);

  // Función para construir la URL de la leyenda
  const getLegendUrl = (wmsUrl, layerName) => {
    const params_raw ={
      SERVICE    : 'WMS',
      REQUEST    : 'GetLegendGraphic',
      FORMAT     : 'image/png', // Puede ser image/png, image/jpeg, etc.
      WIDTH      : 20, // Puedes ajustar el ancho de la leyenda
      HEIGHT     : 20, // Puedes ajustar el alto de la leyenda
      LAYER      : layerName, // El nombre de la capa
      VERSION    : '1.3.0', // Asegúrate de usar la versión correcta del WMS
      TRANSPARENT: 'true'

    }
    if(rule){
      params_raw["RULE"] = rule
    }
    if(style){
      params_raw["STYLE"] = style
    }
    const params = new URLSearchParams(params_raw);
    return `${wmsUrl}?${params.toString()}`;
  };

  // Ejecutar cuando se monta el componente
  useEffect(() => {
    if (wmsUrl && layerName) {
      console.log("Actualizando leyenda WMS", layerName,style)
      const legendGraphicUrl = getLegendUrl(wmsUrl, layerName);
      setLegendUrl(legendGraphicUrl);
    }
  }, [wmsUrl, layerName, rule, style]);

  // Si no hay leyenda, muestra un mensaje
  if (!legendUrl) return <p>Cargando leyenda...</p>;

  // Mostrar la imagen de la leyenda
  return (
    <div>
      <img src={legendUrl} alt={`Leyenda de ${layerName}`} />
    </div>
  );
};

class legendWMS extends DefaultLegendClass{
    constructor(wmsUrl, layerName, title, rule=false, element_title = "", style=false ){
        super()
        this.wmsUrl        = wmsUrl
        this.layerName     = layerName
        this.title         = title
        this.rule          = rule
        this.element_title = element_title
        this.style         = style
    }
    get_legend(){
        if(this.rule){
          return <div style={{display:"flex", justifyContent:"center"}}>
          <WmsLegend wmsUrl={this.wmsUrl} layerName={this.layerName} rule={this.rule} style={this.style}/>
          {this.title && <label style={{fontSize:"0.9rem", paddingTop:"0.1rem", textAlign:"left"}}>{this.element_title}</label>}
          </div>
        }
        return <WmsLegend wmsUrl={this.wmsUrl} layerName={this.layerName} rule={this.rule} style={this.style}/>
    }
    get_name(){
        return this.title
    }


}
export {legendWMS}
