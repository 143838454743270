import { Divider, Paper } from "@mui/material";
import { z_index_values } from "../../../services/Commons/zIndexService";
import { FloatingWindow } from "../../modals/FloatingWindow/FloatingWindow";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../Contexts/AppContext";
import { isMobile } from "react-device-detect";

class DefaultLegendClass {
  get_legend() {
    return <></>;
  }
  get_name() {
    return false;
  }
}

const BaseLegend = () => {
  const [legendsToShow, setLegendsToShow]       = useState([]);
  const { legendUpdater, layersGroups }         = useContext(AppContext);
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [])
  
  useEffect(() => {
    console.log("Cargando leyenda", layersGroups)
    if (
      layersGroups == undefined ||
      layersGroups == null ||
      layersGroups.length <= 0
    ) {
    } else {
      let legends = [];
      layersGroups.map((lg) => {
        if (lg.legends.length > 0) {
          legends = [...legends, ...lg.legends];
        }
      });
      //console.log("Leyendas", legends);
     
      setLegendsToShow(legends)
    }
  }, [layersGroups, legendUpdater]);

  return legendsToShow.length > 0 && layersGroups.length > 0 ? <FloatingWindow
  title="Leyenda"
  content={legendsToShow.map((legend, index) => (
    <><Paper
      id="legend"
      key={index}
      style={{
        display       : "flex",
        flexDirection : "column",
        width         : "calc(100% - 2rem)",
        justifyContent: "flex-start",
        textAlign     : "left",
        padding       : "1rem",
      }}
    >
      <h4>{legend.get_name()}</h4>
      {legend.get_legend()}
    </Paper>
    <Divider  variant="middle" style={{padding:"0.2rem"}}/></>
  ))}
  onClose={false}

  initial_position_x={isMobile ? 15 : windowDimensions.width - 300}
  initial_position_y={ 15}
  initial_width     ={250}
  initial_height    ={320}
  start_collapsed   ={true}
  zIndex            ={z_index_values["default_legend"]}
/>:<></>;
};
export { DefaultLegendClass, BaseLegend };
