function buildCQLFilter(filters) {
    const queryParts = filters.map(filter => {
      const { key, values, operation } = filter;
      
      // Si hay más de un valor, une con la operación (AND/OR)
      if (values.length > 1) {
        const joinedValues = values.map(value => `${key} ${value.includes('>') || value.includes('<') || value.includes('=') ? value : `='${value}'`}`).join(` ${operation} `);
        return `(${joinedValues})`;
      } 
      
      // Si solo hay un valor, lo devuelve directamente
      else {
        const value = values[0];
        return `${key} ${value.includes('>') || value.includes('<') || value.includes('=') ? value : `='${value}'`}`;
      }
    });
  
    // Une todos los filtros con AND entre ellos
    return queryParts.join(' AND ');
}

export {buildCQLFilter}