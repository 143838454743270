import React, { useState } from 'react';
import { Tabs, Tab, Box, createTheme, ThemeProvider } from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';

const DictionaryTabs = ({ tabsData, with_gradient = false }) => {
  const [value, setValue] = useState(0);
  const tabNames = Object.keys(tabsData);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const theme = createTheme({
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            color: 'var(--color-txt-2)',
            backgroundColor:"var(--color-bg-2)",
            transition: '0.2s all ease-in-out',
            border:"1px solid transparent",
            borderRadius:"1rem 1rem 0 0",
            paddingRight:"1rem",
            //borderBottom:"3px solid var(--color-secondary)",
            '&.MuiTab-root':{
              color: 'white',
              backgroundColor:"color-mix(in srgb, rgba(var(--color-primary-raw), 0.8) 30% , rgba(50, 50, 50, 0.8) 70%) !important",
            },
            '&.Mui-selected': {
              color: 'var(--color-primary-txt)',
              backgroundColor: 'var(--color-primary) !important',
              border:"1px solid var(--color-secondary)",
              //borderLeft:"0px solid black",
              zIndex:100
            },
            '&:hover': {
              color: 'var(--color-secondary-txt)',
              backgroundColor: 'var(--color-secondary) !important',
              border:"1px solid var(--color-secondary-txt)",
              borderBottom:"0px solid black",
            },
          },
        },
      },
    },
  });
  return (
    <Box sx={{ width: '100%' }}>
      {/* Contenedor de las pestañas */}

      <ThemeProvider theme={theme}>
      <div style={{width:"100%", minWidth:"100%", minHeight:"30px", height:"30px", background:with_gradient?"linear-gradient(0deg,var(--color-secondary-transparent-30) 0%, rgba(255,255,255, 0) 100%)":"transparent"}}>
      
      </div>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="tabs"
        sx={{
          //backgroundColor:"var(--color-primary-light-80)",
          boxSizing:"border-box",
          backgroundColor:with_gradient?"var(--color-secondary-transparent-30)":"transparent",
          borderBottom:"3px solid var(--color-secondary)",
          minHeight: '48px', // Asegura que el fondo cubra toda el área verticalmente
        }}
      >
        {tabNames.map((name, index) => (
          <Tab label={<strong>{name}</strong>} key={index}  />
        ))}
      </Tabs>
      </ThemeProvider>
      {/* Contenido de las pestañas */}
      {tabNames.map((name, index) => (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`tabpanel-${index}`}
          key={index}
        >
          {value === index && (
            <Box sx={{ p: 2 }}>
              {/* Aquí se muestra el contenido de cada pestaña */}
              {tabsData[name]}
            </Box>
          )}
        </div>
      ))}
    </Box>
  );
};

export default DictionaryTabs;
